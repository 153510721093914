<template>
  <div
    ref="modalPhieuPhauThuat"
    class="modal fade"
    id="modalPhieuPhauThuat"
    tabindex="-1"
    role="dialog"
    aria-labelledby="modalPhieuPhauThuatLabel"
    aria-hidden="true"
  >
    <div class="modal-dialog modal-xl" role="document">
      <div class="modal-content" v-loading="loading">
        <div class="d-flex justify-content-between p-3">
          <div class="fs-24 fw-700 txt-grey-900 text-center w-100">
            <span>PHIẾU PHẪU THUẬT THỦ THUẬT</span>
          </div>
          <button
            type="button"
            id="closeModal"
            class="close bg-white border-0"
            data-dismiss="modal"
            aria-label="Close"
          >
            <span aria-hidden="true" class="fs-30 fw-500">&times;</span>
          </button>
        </div>
        <div class="modal-body">
          <div class="row">
            <div class="col-md-6">
              <div class="fs-14 fw-500">Chọn mẫu Phiếu phẫu thuật thủ thuật</div>
              <el-select
                clearable
                filterable
                v-model="form.doc_template_id"
                class="w-full"
                placeholder="Chọn mẫu phiếu"
                @change="handleChangeDocTemplate"
                remote
                :remote-method="getDocsTemplateList"
                :loading="loading"
              >
                <el-option
                  v-for="item in docsTemplateList"
                  :key="item.id + 'md'"
                  :label="handleGetTemLabel(item)"
                  :value="item.id"
                />
              </el-select>
            </div>
          </div>
          <div class="row mb-3 mt-3">
            <div class="col-md-6 flex h-10">
              <label class="fs-14 fw-700 w-44 mt-2">Ngày tạo phiếu:</label>
              <input v-model="formatterDate" type="date" class="form--input" />
            </div>

            <div class="col-md-6 flex h-10">
              <label class="fs-14 fw-700 w-44 mt-2">Vào viện lúc:</label>
              <input v-model="formatterVisitedAt" type="datetime-local" class="form--input" />
            </div>
          </div>
          <div class="row mt-1 mb-3">
            <div class="col-md-6 flex h-10">
              <label class="fs-14 fw-700 w-44 mt-2">Thời gian bắt đầu:</label>
              <input v-model="form.surgery_at" type="datetime-local" class="form--input" />
            </div>

            <div class="col-md-6 flex h-10">
              <label class="fs-14 fw-700 w-44 mt-2">Thời gian kết thúc:</label>
              <input
                :value="form.completed_at"
                @change="handleChangeCompletedAt"
                type="datetime-local"
                class="form--input"
              />
            </div>
          </div>
          <div class="m-0 mb-3">
            <div>
              <label for="txt-name" class="fs-14 fw-700 mb-1">Chẩn đoán:</label>
              <div class="mb-1">
                <p>- Trước phẫu thuật/ thủ thuật</p>
                <CkeditorNormal
                  id="before_diagnose-ppt"
                  @onEditorInput="(t) => { form.before_diagnose = t }"
                  :prop_text="form.before_diagnose"
                  :placeholder="''"
                  :class_style="'bg-white p-0 txt-black'"
                ></CkeditorNormal>
              </div>
              <div>
                <p>- Sau phẫu thuật/ thủ thuật</p>
                <CkeditorNormal
                  id="after_diagnose-ppt"
                  @onEditorInput="(t) => { form.after_diagnose = t }"
                  :prop_text="form.after_diagnose"
                  :placeholder="''"
                  :class_style="'bg-white p-0 txt-black'"
                ></CkeditorNormal>
                <!-- <CkeditorNormal @onEditorInput="
                  (t) => {
                    form.after_diagnose = t;
                  }
                " :prop_text="form.after_diagnose" :placeholder="''" :class_style="'bg-white p-0 txt-black'"
                :id="'inputTextConclusion'"></CkeditorNormal>-->
              </div>
            </div>
          </div>
          <div class="m-0 mb-3">
            <div>
              <label for="txt-name" class="fs-14 fw-700 mb-1">Phương pháp phẫu thuật/ thủ thuật</label>
              <CkeditorNormal
                id="surgery_method-ppt"
                @onEditorInput="(t) => { form.surgery_method = t }"
                :prop_text="form.surgery_method"
                :placeholder="''"
                :class_style="'bg-white p-0 txt-black'"
              ></CkeditorNormal>
              <!-- <CkeditorNormal @onEditorInput="
                (t) => {
                  form.surgery_method = t;
                }
              " :prop_text="form.surgery_method" :placeholder="''" :class_style="'bg-white p-0 txt-black'"
              :id="'inputTextConclusion'"></CkeditorNormal>-->
            </div>
          </div>
          <div class="m-0 mb-3">
            <div>
              <label
                for="txt-name"
                class="fs-14 fw-700 mb-1 required"
              >Loại phẫu thuật/ thủ thuật (bắt buộc)</label>
              <input
                type="text"
                class="border form-control bg-white w-full"
                :style="isError ? 'border-color: red!important' : ''"
                v-model="form.surgery_type"
              />
            </div>
          </div>
          <div class="m-0 mb-3">
            <div>
              <label
                for="txt-name"
                class="fs-14 fw-700 mb-1"
              >Phương pháp phẫu thuật/ thủ thuật thứ 2</label>
              <CkeditorNormal
                id="surgery_method_2-ppt"
                @onEditorInput="(t) => { form.surgery_method_2 = t }"
                :prop_text="form.surgery_method_2"
                :placeholder="''"
                :class_style="'bg-white p-0 txt-black'"
              ></CkeditorNormal>
              <!-- <CkeditorNormal @onEditorInput="
                (t) => {
                  form.surgery_method_2 = t;
                }
              " :prop_text="form.surgery_method_2" :placeholder="''" :class_style="'bg-white p-0 txt-black'"
              :id="'inputTextConclusion'"></CkeditorNormal>-->
            </div>
          </div>
          <div class="m-0 mb-3">
            <div>
              <label for="txt-name" class="fs-14 fw-700 mb-1">Phương pháp vô cảm</label>
              <CkeditorNormal
                id="anaesthetic_method-ppt"
                @onEditorInput="(t) => { form.anaesthetic_method = t }"
                :prop_text="form.anaesthetic_method"
                :placeholder="''"
                :class_style="'bg-white p-0 txt-black'"
              ></CkeditorNormal>
              <!-- <CkeditorNormal @onEditorInput="
                (t) => {
                  form.anaesthetic_method = t;
                }
              " :prop_text="form.anaesthetic_method" :placeholder="''" :class_style="'bg-white p-0 txt-black'"
              :id="'inputTextConclusion'"></CkeditorNormal>-->
            </div>
          </div>
          <div class="m-0 mb-3">
            <div>
              <label for="txt-name" class="fs-14 fw-700 mb-1">Tổng số người tham gia phẫu thuật</label>
              <input
                type="number"
                class="border form-control bg-white w-full"
                v-model="form.number_of_people"
              />
            </div>
          </div>

          <div class="row mb-3">
            <div class="col-4">
              <div class>
                <label class="fs-14 fw-700">Bác sĩ phẫu thuật/thủ thuật chính</label>
                <el-select
                  v-model="form.surgical_main_id"
                  filterable
                  allow-create
                  class="w-full"
                  placeholder="Chọn bác sĩ"
                >
                  <el-option
                    v-for="item in doctors"
                    :key="item.id + 'td'"
                    :label="item.name"
                    :value="item.user_id"
                  />
                </el-select>
              </div>
            </div>
            <div class="col-4">
              <div class>
                <label class="fs-14 fw-700">BS phụ 1</label>
                <el-select
                  v-model="form.surgical_extra_id_1"
                  filterable
                  allow-create
                  class="w-full"
                  placeholder="Chọn bác sĩ"
                >
                  <el-option
                    v-for="item in doctors"
                    :key="item.id + 'td'"
                    :label="item.name"
                    :value="item.user_id"
                  />
                </el-select>
              </div>
            </div>
            <div class="col-4">
              <div class>
                <label class="fs-14 fw-700">BS phụ 2</label>
                <el-select
                  v-model="form.surgical_extra_id_2"
                  filterable
                  allow-create
                  class="w-full"
                  placeholder="Chọn bác sĩ"
                >
                  <el-option
                    v-for="item in doctors"
                    :key="item.id + 'td'"
                    :label="item.name"
                    :value="item.user_id"
                  />
                </el-select>
              </div>
            </div>
          </div>

          <div class="row mb-3">
            <div class="col-4">
              <div class>
                <label class="fs-14 fw-700">Bác sĩ gây mê hồi sức chính</label>
                <el-select
                  v-model="form.anaesthetic_main_id"
                  filterable
                  allow-create
                  class="w-full"
                  placeholder="Chọn bác sĩ"
                >
                  <el-option
                    v-for="item in doctors"
                    :key="item.id + 'td'"
                    :label="item.name"
                    :value="item.user_id"
                  />
                </el-select>
              </div>
            </div>
            <div class="col-4">
              <div class>
                <label class="fs-14 fw-700">BS gây mê phụ</label>
                <el-select
                  v-model="form.anaesthetic_extra_id"
                  filterable
                  allow-create
                  class="w-full"
                  placeholder="Chọn bác sĩ"
                >
                  <el-option
                    v-for="item in doctors"
                    :key="item.id + 'td'"
                    :label="item.name"
                    :value="item.user_id"
                  />
                </el-select>
              </div>
            </div>
            <!-- <div class="col-4">
              <div class>
                <label class="fs-14 fw-700">Giúp việc PT</label>
                <el-select
                  v-model="form.pt_extra_id"
                  filterable
                  allow-create
                  class="w-full"
                  placeholder="Chọn bác sĩ"
                >
                  <el-option
                    v-for="item in doctors"
                    :key="item.id + 'td'"
                    :label="item.name"
                    :value="item.user_id"
                  />
                </el-select>
              </div>
            </div>-->
          </div>

          <div class="row mb-3 mt-3">
            <div class="col-md-6">
              <div class="fs-14 fw-700 text-center">LƯỢC ĐỒ PHẪU THUẬT/THỦ THUẬT</div>
              <span class>Ảnh 1</span>

              <div
                class="relative col flex align-items-center mb-2 mt-2 hodo-hover"
                :class="imageSrc1?'':'hodo-hover'"
              >
                <div
                  v-if="!imageSrc1"
                  @click="showModalDrawImage(form.surgery_note_image01,1)"
                  class="hodo-overlay cursor-pointer"
                >
                  <svg
                    width="80"
                    height="80"
                    viewBox="0 0 80 80"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                    class="add-icon-cs"
                  >
                    <path
                      opacity="0.1"
                      fill-rule="evenodd"
                      clip-rule="evenodd"
                      d="M40 80C62.0914 80 80 62.0914 80 40C80 17.9086 62.0914 0 40 0C17.9086 0 0 17.9086 0 40C0 62.0914 17.9086 80 40 80Z"
                      fill="white"
                    />
                    <path
                      fill-rule="evenodd"
                      clip-rule="evenodd"
                      d="M41.6 38.3996H52.8C53.6836 38.3996 54.4 39.116 54.4 39.9996C54.4 40.8833 53.6836 41.5996 52.8 41.5996H41.6V52.7996C41.6 53.6833 40.8836 54.3996 40 54.3996C39.1163 54.3996 38.4 53.6833 38.4 52.7996V41.5996H27.2C26.3163 41.5996 25.6 40.8833 25.6 39.9996C25.6 39.116 26.3163 38.3996 27.2 38.3996H38.4V27.1996C38.4 26.316 39.1163 25.5996 40 25.5996C40.8836 25.5996 41.6 26.316 41.6 27.1996V38.3996Z"
                      fill="#20419B"
                    />
                  </svg>
                </div>
                <div
                  v-else
                  @click="showModalDrawImage(form.surgery_note_image01,1)"
                  class="absolute top-2 right-2 cursor-pointer"
                >
                  <svg
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M21 11.9999C20.7348 11.9999 20.4804 12.1053 20.2929 12.2928C20.1054 12.4804 20 12.7347 20 12.9999V18.9999C20 19.2652 19.8946 19.5195 19.7071 19.707C19.5196 19.8946 19.2652 19.9999 19 19.9999H5C4.73478 19.9999 4.48043 19.8946 4.29289 19.707C4.10536 19.5195 4 19.2652 4 18.9999V4.99994C4 4.73472 4.10536 4.48037 4.29289 4.29283C4.48043 4.1053 4.73478 3.99994 5 3.99994H11C11.2652 3.99994 11.5196 3.89458 11.7071 3.70705C11.8946 3.51951 12 3.26516 12 2.99994C12 2.73472 11.8946 2.48037 11.7071 2.29283C11.5196 2.1053 11.2652 1.99994 11 1.99994H5C4.20435 1.99994 3.44129 2.31601 2.87868 2.87862C2.31607 3.44123 2 4.20429 2 4.99994V18.9999C2 19.7956 2.31607 20.5587 2.87868 21.1213C3.44129 21.6839 4.20435 21.9999 5 21.9999H19C19.7956 21.9999 20.5587 21.6839 21.1213 21.1213C21.6839 20.5587 22 19.7956 22 18.9999V12.9999C22 12.7347 21.8946 12.4804 21.7071 12.2928C21.5196 12.1053 21.2652 11.9999 21 11.9999ZM6 12.7599V16.9999C6 17.2652 6.10536 17.5195 6.29289 17.707C6.48043 17.8946 6.73478 17.9999 7 17.9999H11.24C11.3716 18.0007 11.5021 17.9755 11.6239 17.9257C11.7457 17.8759 11.8566 17.8026 11.95 17.7099L18.87 10.7799L21.71 7.99994C21.8037 7.90698 21.8781 7.79637 21.9289 7.67452C21.9797 7.55266 22.0058 7.42195 22.0058 7.28994C22.0058 7.15793 21.9797 7.02722 21.9289 6.90536C21.8781 6.7835 21.8037 6.6729 21.71 6.57994L17.47 2.28994C17.377 2.19621 17.2664 2.12182 17.1446 2.07105C17.0227 2.02028 16.892 1.99414 16.76 1.99414C16.628 1.99414 16.4973 2.02028 16.3754 2.07105C16.2536 2.12182 16.143 2.19621 16.05 2.28994L13.23 5.11994L6.29 12.0499C6.19732 12.1434 6.12399 12.2542 6.07423 12.376C6.02446 12.4979 5.99924 12.6283 6 12.7599ZM16.76 4.40994L19.59 7.23994L18.17 8.65994L15.34 5.82994L16.76 4.40994ZM8 13.1699L13.93 7.23994L16.76 10.0699L10.83 15.9999H8V13.1699Z"
                      fill="#476DD6"
                    />
                  </svg>
                </div>
                <img
                  @click="onShowMedia(imageList, 0)"
                  class="object-contain w-full h-full"
                  :src="imageSrc1 || imageDefault"
                  alt
                />
                <input
                  ref="inputFilePPT1"
                  accept=".jpeg, .jpg, .gif, .png"
                  class="d-none"
                  type="file"
                  @change="selectFile1"
                />
              </div>
              <span class>Ảnh 2</span>
              <div
                class="relative col flex align-items-center mb-2 mt-2"
                :class="imageSrc2?'':'hodo-hover'"
              >
                <div
                  v-if="!imageSrc2"
                  @click="showModalDrawImage(form.surgery_note_image02,2)"
                  class="hodo-overlay cursor-pointer"
                >
                  <svg
                    width="80"
                    height="80"
                    viewBox="0 0 80 80"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                    class="add-icon-cs"
                  >
                    <path
                      opacity="0.1"
                      fill-rule="evenodd"
                      clip-rule="evenodd"
                      d="M40 80C62.0914 80 80 62.0914 80 40C80 17.9086 62.0914 0 40 0C17.9086 0 0 17.9086 0 40C0 62.0914 17.9086 80 40 80Z"
                      fill="white"
                    />
                    <path
                      fill-rule="evenodd"
                      clip-rule="evenodd"
                      d="M41.6 38.3996H52.8C53.6836 38.3996 54.4 39.116 54.4 39.9996C54.4 40.8833 53.6836 41.5996 52.8 41.5996H41.6V52.7996C41.6 53.6833 40.8836 54.3996 40 54.3996C39.1163 54.3996 38.4 53.6833 38.4 52.7996V41.5996H27.2C26.3163 41.5996 25.6 40.8833 25.6 39.9996C25.6 39.116 26.3163 38.3996 27.2 38.3996H38.4V27.1996C38.4 26.316 39.1163 25.5996 40 25.5996C40.8836 25.5996 41.6 26.316 41.6 27.1996V38.3996Z"
                      fill="#20419B"
                    />
                  </svg>
                </div>
                <div
                  v-else
                  @click="showModalDrawImage(form.surgery_note_image02,2)"
                  class="absolute top-2 right-2 cursor-pointer"
                >
                  <svg
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M21 11.9999C20.7348 11.9999 20.4804 12.1053 20.2929 12.2928C20.1054 12.4804 20 12.7347 20 12.9999V18.9999C20 19.2652 19.8946 19.5195 19.7071 19.707C19.5196 19.8946 19.2652 19.9999 19 19.9999H5C4.73478 19.9999 4.48043 19.8946 4.29289 19.707C4.10536 19.5195 4 19.2652 4 18.9999V4.99994C4 4.73472 4.10536 4.48037 4.29289 4.29283C4.48043 4.1053 4.73478 3.99994 5 3.99994H11C11.2652 3.99994 11.5196 3.89458 11.7071 3.70705C11.8946 3.51951 12 3.26516 12 2.99994C12 2.73472 11.8946 2.48037 11.7071 2.29283C11.5196 2.1053 11.2652 1.99994 11 1.99994H5C4.20435 1.99994 3.44129 2.31601 2.87868 2.87862C2.31607 3.44123 2 4.20429 2 4.99994V18.9999C2 19.7956 2.31607 20.5587 2.87868 21.1213C3.44129 21.6839 4.20435 21.9999 5 21.9999H19C19.7956 21.9999 20.5587 21.6839 21.1213 21.1213C21.6839 20.5587 22 19.7956 22 18.9999V12.9999C22 12.7347 21.8946 12.4804 21.7071 12.2928C21.5196 12.1053 21.2652 11.9999 21 11.9999ZM6 12.7599V16.9999C6 17.2652 6.10536 17.5195 6.29289 17.707C6.48043 17.8946 6.73478 17.9999 7 17.9999H11.24C11.3716 18.0007 11.5021 17.9755 11.6239 17.9257C11.7457 17.8759 11.8566 17.8026 11.95 17.7099L18.87 10.7799L21.71 7.99994C21.8037 7.90698 21.8781 7.79637 21.9289 7.67452C21.9797 7.55266 22.0058 7.42195 22.0058 7.28994C22.0058 7.15793 21.9797 7.02722 21.9289 6.90536C21.8781 6.7835 21.8037 6.6729 21.71 6.57994L17.47 2.28994C17.377 2.19621 17.2664 2.12182 17.1446 2.07105C17.0227 2.02028 16.892 1.99414 16.76 1.99414C16.628 1.99414 16.4973 2.02028 16.3754 2.07105C16.2536 2.12182 16.143 2.19621 16.05 2.28994L13.23 5.11994L6.29 12.0499C6.19732 12.1434 6.12399 12.2542 6.07423 12.376C6.02446 12.4979 5.99924 12.6283 6 12.7599ZM16.76 4.40994L19.59 7.23994L18.17 8.65994L15.34 5.82994L16.76 4.40994ZM8 13.1699L13.93 7.23994L16.76 10.0699L10.83 15.9999H8V13.1699Z"
                      fill="#476DD6"
                    />
                  </svg>
                </div>
                <img
                  @click="onShowMedia(imageList, 1)"
                  class="object-contain w-full h-full cursor-pointer"
                  :src="imageSrc2 || imageDefault"
                  alt
                />
                <input
                  ref="inputFilePPT1"
                  accept=".jpeg, .jpg, .gif, .png"
                  class="d-none"
                  type="file"
                  @change="selectFile2"
                />
              </div>
              <!-- <div class="col flex align-items-center mb-2 mt-2">
              <img class="object-contain h-16 w-32" :src="imageSrc2 || imageDefault" alt />-->

              <!-- <div
                  class="justify-center items-center flex cursor-pointer px-2"
                  @click="showPickFile2()"
                >
                  <svg
                    width="24"
                    height="24"
                    viewBox="0 0 32 32"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M16.0001 29.3337C23.3334 29.3337 29.3334 23.3337 29.3334 16.0003C29.3334 8.66699 23.3334 2.66699 16.0001 2.66699C8.66675 2.66699 2.66675 8.66699 2.66675 16.0003C2.66675 23.3337 8.66675 29.3337 16.0001 29.3337Z"
                      stroke="#476DD6"
                      stroke-width="1.5"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                    <path
                      d="M10.6667 16H21.3334"
                      stroke="#476DD6"
                      stroke-width="1.5"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                    <path
                      d="M16 21.3337V10.667"
                      stroke="#476DD6"
                      stroke-width="1.5"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                  </svg>
              </div>-->
              <!-- <div
                  class="justify-center items-center flex cursor-pointer px-2 ml-2"
                  @click="showModalDrawImage(form.surgery_note_image02,2)"
                  
                >
                  <svg
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M21 11.9999C20.7348 11.9999 20.4804 12.1053 20.2929 12.2928C20.1054 12.4804 20 12.7347 20 12.9999V18.9999C20 19.2652 19.8946 19.5195 19.7071 19.707C19.5196 19.8946 19.2652 19.9999 19 19.9999H5C4.73478 19.9999 4.48043 19.8946 4.29289 19.707C4.10536 19.5195 4 19.2652 4 18.9999V4.99994C4 4.73472 4.10536 4.48037 4.29289 4.29283C4.48043 4.1053 4.73478 3.99994 5 3.99994H11C11.2652 3.99994 11.5196 3.89458 11.7071 3.70705C11.8946 3.51951 12 3.26516 12 2.99994C12 2.73472 11.8946 2.48037 11.7071 2.29283C11.5196 2.1053 11.2652 1.99994 11 1.99994H5C4.20435 1.99994 3.44129 2.31601 2.87868 2.87862C2.31607 3.44123 2 4.20429 2 4.99994V18.9999C2 19.7956 2.31607 20.5587 2.87868 21.1213C3.44129 21.6839 4.20435 21.9999 5 21.9999H19C19.7956 21.9999 20.5587 21.6839 21.1213 21.1213C21.6839 20.5587 22 19.7956 22 18.9999V12.9999C22 12.7347 21.8946 12.4804 21.7071 12.2928C21.5196 12.1053 21.2652 11.9999 21 11.9999ZM6 12.7599V16.9999C6 17.2652 6.10536 17.5195 6.29289 17.707C6.48043 17.8946 6.73478 17.9999 7 17.9999H11.24C11.3716 18.0007 11.5021 17.9755 11.6239 17.9257C11.7457 17.8759 11.8566 17.8026 11.95 17.7099L18.87 10.7799L21.71 7.99994C21.8037 7.90698 21.8781 7.79637 21.9289 7.67452C21.9797 7.55266 22.0058 7.42195 22.0058 7.28994C22.0058 7.15793 21.9797 7.02722 21.9289 6.90536C21.8781 6.7835 21.8037 6.6729 21.71 6.57994L17.47 2.28994C17.377 2.19621 17.2664 2.12182 17.1446 2.07105C17.0227 2.02028 16.892 1.99414 16.76 1.99414C16.628 1.99414 16.4973 2.02028 16.3754 2.07105C16.2536 2.12182 16.143 2.19621 16.05 2.28994L13.23 5.11994L6.29 12.0499C6.19732 12.1434 6.12399 12.2542 6.07423 12.376C6.02446 12.4979 5.99924 12.6283 6 12.7599ZM16.76 4.40994L19.59 7.23994L18.17 8.65994L15.34 5.82994L16.76 4.40994ZM8 13.1699L13.93 7.23994L16.76 10.0699L10.83 15.9999H8V13.1699Z"
                      fill="#476DD6"
                    />
                  </svg>
              </div>-->
              <!-- <input
                  ref="inputFilePPT2"
                  accept=".jpeg, .jpg, .gif, .png"
                  class="d-none"
                  type="file"
                  @change="selectFile2"
              />-->
              <!-- </div> -->
              <!-- <span class>Ảnh 3</span> -->
              <!-- <div
                class="relative col flex align-items-center mb-2 mt-2"
                :class="imageSrc3 ? '' : 'hodo-hover'"
              >
                <div
                  v-if="!imageSrc3"
                  @click="showModalDrawImage(form.surgery_note_image03,3)"
                  class="hodo-overlay cursor-pointer"
                >
                  <svg
                    width="80"
                    height="80"
                    viewBox="0 0 80 80"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                    class="add-icon-cs"
                  >
                    <path
                      opacity="0.1"
                      fill-rule="evenodd"
                      clip-rule="evenodd"
                      d="M40 80C62.0914 80 80 62.0914 80 40C80 17.9086 62.0914 0 40 0C17.9086 0 0 17.9086 0 40C0 62.0914 17.9086 80 40 80Z"
                      fill="white"
                    />
                    <path
                      fill-rule="evenodd"
                      clip-rule="evenodd"
                      d="M41.6 38.3996H52.8C53.6836 38.3996 54.4 39.116 54.4 39.9996C54.4 40.8833 53.6836 41.5996 52.8 41.5996H41.6V52.7996C41.6 53.6833 40.8836 54.3996 40 54.3996C39.1163 54.3996 38.4 53.6833 38.4 52.7996V41.5996H27.2C26.3163 41.5996 25.6 40.8833 25.6 39.9996C25.6 39.116 26.3163 38.3996 27.2 38.3996H38.4V27.1996C38.4 26.316 39.1163 25.5996 40 25.5996C40.8836 25.5996 41.6 26.316 41.6 27.1996V38.3996Z"
                      fill="#20419B"
                    />
                  </svg>
                </div>
                <div
                  v-else
                  @click="showModalDrawImage(form.surgery_note_image03,3)"
                  class="absolute top-2 right-2 cursor-pointer"
                >
                  <svg
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M21 11.9999C20.7348 11.9999 20.4804 12.1053 20.2929 12.2928C20.1054 12.4804 20 12.7347 20 12.9999V18.9999C20 19.2652 19.8946 19.5195 19.7071 19.707C19.5196 19.8946 19.2652 19.9999 19 19.9999H5C4.73478 19.9999 4.48043 19.8946 4.29289 19.707C4.10536 19.5195 4 19.2652 4 18.9999V4.99994C4 4.73472 4.10536 4.48037 4.29289 4.29283C4.48043 4.1053 4.73478 3.99994 5 3.99994H11C11.2652 3.99994 11.5196 3.89458 11.7071 3.70705C11.8946 3.51951 12 3.26516 12 2.99994C12 2.73472 11.8946 2.48037 11.7071 2.29283C11.5196 2.1053 11.2652 1.99994 11 1.99994H5C4.20435 1.99994 3.44129 2.31601 2.87868 2.87862C2.31607 3.44123 2 4.20429 2 4.99994V18.9999C2 19.7956 2.31607 20.5587 2.87868 21.1213C3.44129 21.6839 4.20435 21.9999 5 21.9999H19C19.7956 21.9999 20.5587 21.6839 21.1213 21.1213C21.6839 20.5587 22 19.7956 22 18.9999V12.9999C22 12.7347 21.8946 12.4804 21.7071 12.2928C21.5196 12.1053 21.2652 11.9999 21 11.9999ZM6 12.7599V16.9999C6 17.2652 6.10536 17.5195 6.29289 17.707C6.48043 17.8946 6.73478 17.9999 7 17.9999H11.24C11.3716 18.0007 11.5021 17.9755 11.6239 17.9257C11.7457 17.8759 11.8566 17.8026 11.95 17.7099L18.87 10.7799L21.71 7.99994C21.8037 7.90698 21.8781 7.79637 21.9289 7.67452C21.9797 7.55266 22.0058 7.42195 22.0058 7.28994C22.0058 7.15793 21.9797 7.02722 21.9289 6.90536C21.8781 6.7835 21.8037 6.6729 21.71 6.57994L17.47 2.28994C17.377 2.19621 17.2664 2.12182 17.1446 2.07105C17.0227 2.02028 16.892 1.99414 16.76 1.99414C16.628 1.99414 16.4973 2.02028 16.3754 2.07105C16.2536 2.12182 16.143 2.19621 16.05 2.28994L13.23 5.11994L6.29 12.0499C6.19732 12.1434 6.12399 12.2542 6.07423 12.376C6.02446 12.4979 5.99924 12.6283 6 12.7599ZM16.76 4.40994L19.59 7.23994L18.17 8.65994L15.34 5.82994L16.76 4.40994ZM8 13.1699L13.93 7.23994L16.76 10.0699L10.83 15.9999H8V13.1699Z"
                      fill="#476DD6"
                    />
                  </svg>
                </div>
                <img
                  @click="onShowMedia(imageList, 2)"
                  class="object-contain w-full h-full"
                  :src="imageSrc3 || imageDefault"
                  alt
                />
                <input
                  ref="inputFilePPT1"
                  accept=".jpeg, .jpg, .gif, .png"
                  class="d-none"
                  type="file"
                  @change="selectFile3"
                />
              </div>-->
            </div>
            <div class="col-md-6">
              <div class="mb-4">
                <label
                  class="text-center w-full fs-14 fw-700 mb-2 uppercase"
                >Mô tả cho hình ảnh lược đồ</label>
                <div class="mt-3">
                  <TipTapEditor
                    :value="form.surgery_image_notes"
                    @setValue="(t)=>form.surgery_image_notes=t"
                  />
                  <!-- <CkeditorNormal
                    id="surgery_image_notes-ppt"
                    @onEditorInput="(t) => { form.surgery_image_notes = t }"
                    :prop_text="form.surgery_image_notes"
                    :placeholder="''"
                    :class_style="'bg-white p-0 txt-black'"
                  ></CkeditorNormal>-->
                </div>
                <div>
                  <TipTapEditor
                    :value="form.template_input"
                    @setValue="(t)=>form.template_input=t"
                  />
                  <!-- <CkeditorNormal
                    id="template_input-ppt"
                    :prop_text="form.template_input"
                    :placeholder="''"
                    :class_style="'bg-white p-0 txt-black template_input'"
                  ></CkeditorNormal>-->
                </div>
              </div>
              <div>
                <label
                  class="text-center w-full fs-14 fw-700 mb-2 uppercase"
                >TRÌNH TỰ PHẪU THUẬT/THỦ THUẬT</label>
                <TipTapEditor :value="form.surgery_notes" @setValue="(t)=>form.surgery_notes=t" />
                <!-- <CkeditorNormal
                  id="surgery_notes-ppt"
                  @onEditorInput="(t) => { form.surgery_notes = t }"
                  :prop_text="form.surgery_notes"
                  :placeholder="''"
                  :class_style="'bg-white p-0 txt-black'"
                ></CkeditorNormal>-->
              </div>
            </div>
          </div>
        </div>
        <div class="modal-footer">
          <button @click="onSubmit" type="button" class="btn bg-pri text-white">Lưu phiếu</button>
        </div>
      </div>
    </div>

    <div
      ref="modalDrawImage"
      class="modal top fade"
      id="modalDrawImage"
      tabindex="-1"
      role="dialog"
    >
      <div class="modal-dialog modal-fullscreen" role="document">
        <div class="modal-content" v-loading="loading">
          <div class="d-flex justify-content-between p-3">
            <div class="fs-24 fw-700 txt-grey-900 text-center w-100"></div>
            <button
              type="button"
              id="closeModalDrawImage"
              class="close bg-white border-0"
              @click="closeModalDrawImage()"
            >
              <span class="fs-30 fw-500">&times;</span>
            </button>
          </div>
          <div class="modal-body flex items-center justify-center p-0">
            <DrawImage :imageProp="imageFileSelected" @onSaveFile="handleSaveDrawImage" />
          </div>
        </div>
      </div>
    </div>
    <CoolLightBox
      :items="getImagesForCoolight(list_medias)"
      :index="index_media"
      :useZoomBar="true"
      @close="index_media = null"
    ></CoolLightBox>
  </div>
</template>
<script>
import CkeditorNormal from '@/components/Editors/CkeditorNormal.vue'
import moment from 'moment'
import { mapState } from 'vuex'
import appUtils from '../../../utils/appUtils'
import TipTapEditor from '@/components/Editors/Tiptap'
import { MENU_EMR_TEMPLATE } from '../../../utils/constants'
import DrawImage from '@/components/DrawImage.vue'

export default {
  name: 'ModalCreatePPT',
  components: { CkeditorNormal, TipTapEditor, DrawImage },
  props: ['type'],
  data () {
    const tempSurgeryInput = `<div class="flex"><div class="mr-1 whitespace-nowrap">- Dẫn lưu:</div><div class="content-none mb-1 w-full" style="border-bottom:2px dotted #000"></div></div><div class="flex"><div class="mr-1 whitespace-nowrap">- Bấc:</div><div class="content-none mb-1 w-full" style="border-bottom:2px dotted #000"></div></div><div class="flex"><div class="mr-1 whitespace-nowrap">- Ngày rút:</div><div class="content-none mb-1 w-full" style="border-bottom:2px dotted #000"></div></div><div class="flex"><div class="mr-1 whitespace-nowrap">- Ngày cắt chỉ:</div><div class="content-none mb-1 w-full" style="border-bottom:2px dotted #000"></div></div><div><div class="flex"><div class="mr-1 whitespace-nowrap">- Khác:</div><div class="content-none mb-1 w-full" style="border-bottom:2px dotted #000"></div></div><div class="content-none mt-1 mb-3 pb-2 w-full" style="border-bottom:2px dotted #000"></div></div>`
    const defaultForm = {
      date: moment().format('YYYY-MM-DD'),
      ref_id: '',
      medical_record_id: '',
      surgery_at: moment().format('YYYY-MM-DD HH:mm'),
      completed_at: moment().format('YYYY-MM-DD HH:mm'),
      before_diagnose: '',
      after_diagnose: '',
      surgery_method: '',
      surgery_method_2: '',
      anaesthetic_method: '',
      surgical_main_id: '',
      surgical_supports_id: '',
      anaesthetic_main_id: '',
      surgical_main_name: '',
      surgical_supports_name: '',
      anaesthetic_main_name: '',
      surgery_notes: '',
      surgery_note_image01: '',
      surgery_note_image02: '',
      surgery_note_image03: '',
      surgery_note_image04: '',
      surgery_note_image05: '',
      surgeons_id: '',
      visited_at: '',
      surgery_type: '',
      number_of_people: null,
      surgical_extra_id_1: '',
      surgical_extra_name_1: '',
      surgical_extra_id_2: '',
      surgical_extra_name_2: '',
      anaesthetic_extra_id: '',
      anaesthetic_extra_name: '',
      pt_extra_id: '',
      pt_extra_name: '',
      surgery_image_notes: '',
      template_input: tempSurgeryInput,
      doc_template_id: null
    }

    return {
      arrFiles: [],
      file: null,
      imageDefault: '../assets/images/default-thumbnail.jpg',
      form: defaultForm,
      defaultForm,
      doctors: null,
      loading: false,
      imageSrc1: null,
      imageSrc2: null,
      imageSrc3: null,
      imageSrc4: null,
      imageSrc5: null,
      tempSurgeryInput,
      appUtils,
      isError: false,
      docsTemplateList: [],
      imageFileSelected: '',
      selectedImage: '',
      list_medias: null,
      index_media: null
    }
  },
  mounted () {
    this.getDoctors()
    this.getDocsTemplateList()
    if (this.type === 'update') {
      this.showSurgery()
    }

    if (this.type === 'create') {
      let self = this
      self.$nextTick(function () {
        window.$('#modalPhieuPhauThuat').on('shown.bs.modal', function (e) {
          self.form.before_diagnose =
            self.form.before_diagnose ||
            self.medicalRecordMain?.treat_dept_main_name ||
            ''
        })

        window.$('#modalPhieuPhauThuat').on('hidden.bs.modal', function (e) {
          self.form = self.defaultForm
          self.isError = false
        })
      })
    }
  },
  computed: {
    ...mapState({
      medicalRecordMain: state => state.medicalRecordMain,
      emrData: state => state.emrData
    }),
    formatterDate: {
      get () {
        return appUtils.formatInputDate(this.form.date)
      },
      set (newDate) {
        this.form.date = newDate
        return newDate
      }
    },
    formatterVisitedAt: {
      get () {
        const visitedAt =
          this.form.visited_at || this.medicalRecordMain?.reception_at || null
        return visitedAt !== null ? appUtils.formatInputDateTime(visitedAt) : ''
      },
      set (newDate) {
        this.form.visited_at = newDate
        return newDate
      }
    },
    imageList () {
      return [this.imageSrc1, this.imageSrc2, this.imageSrc3].filter(
        item => item !== null
      )
    }
  },
  methods: {
    onShowMedia (medias, index) {
      let self = this
      self.list_medias = medias
      self.index_media = index
    },
    getImagesForCoolight (files) {
      let results = []
      if (files) {
        files.forEach(file => {
          results.push({
            src: file
          })
        })
      }
      return results
    },
    selectedFile (data, preview) {
      if (!data.target.files?.length) return

      const file = data.target.files[0]
      const theReader = new FileReader()

      if (!appUtils.isImage(file)) {
        this.$message({
          type: 'warning',
          message: this.$t(
            // `The file uploaded isn't an image. You won't be able to see detailed contents of the file.`
            `Tệp được tải lên không phải là hình ảnh. Bạn sẽ không thể xem nội dung chi tiết của tệp tin.`
          )
        })
      }

      theReader.onloadend = async () => {
        preview = await theReader.result
      }
      theReader.readAsDataURL(file)

      return file
    },
    selectFile1 (data) {
      if (!data.target.files?.length) return
      const file = data.target.files[0]
      if (file.name.toLowerCase().match(/\.(jpeg|jpg|gif|png)$/) === null) {
        this.$toast.open({
          message: 'Không thể chọn tệp ' + file.name + ' do không hợp lệ',
          type: 'warning'
        })
        return
      }
      this.form.surgery_note_image01 = file

      const theReader = new FileReader()
      theReader.onloadend = async () => {
        this.imageSrc1 = await theReader.result
      }
      theReader.readAsDataURL(file)
      this.$refs.inputFilePPT1.value = null

      // this.form.surgery_note_image01 = file
      // this.$refs.inputFilePPT1.value = null
    },
    selectFile2 (data) {
      if (!data.target.files?.length) return
      const file = data.target.files[0]
      if (file.name.toLowerCase().match(/\.(jpeg|jpg|gif|png)$/) === null) {
        this.$toast.open({
          message: 'Không thể chọn tệp ' + file.name + ' do không hợp lệ',
          type: 'warning'
        })
        return
      }
      this.form.surgery_note_image02 = file
      const theReader = new FileReader()
      theReader.onloadend = async () => {
        this.imageSrc2 = await theReader.result
      }
      theReader.readAsDataURL(file)
      this.$refs.inputFilePPT2.value = null
    },
    selectFile3 (data) {
      if (!data.target.files?.length) return
      const file = data.target.files[0]
      if (file.name.toLowerCase().match(/\.(jpeg|jpg|gif|png)$/) === null) {
        this.$toast.open({
          message: 'Không thể chọn tệp ' + file.name + ' do không hợp lệ',
          type: 'warning'
        })
        return
      }
      this.form.surgery_note_image03 = file
      const theReader = new FileReader()
      theReader.onloadend = async () => {
        this.imageSrc3 = await theReader.result
      }
      theReader.readAsDataURL(file)
      this.$refs.inputFilePPT3.value = null
    },
    selectFile4 (data) {
      if (!data.target.files?.length) return
      const file = data.target.files[0]
      if (file.name.toLowerCase().match(/\.(jpeg|jpg|gif|png)$/) === null) {
        this.$toast.open({
          message: 'Không thể chọn tệp ' + file.name + ' do không hợp lệ',
          type: 'warning'
        })
        return
      }
      this.form.surgery_note_image04 = file
      const theReader = new FileReader()
      theReader.onloadend = async () => {
        this.imageSrc4 = await theReader.result
      }
      theReader.readAsDataURL(file)
      this.$refs.inputFilePPT4.value = null
    },
    selectFile5 (data) {
      if (!data.target.files?.length) return
      const file = data.target.files[0]
      if (file.name.toLowerCase().match(/\.(jpeg|jpg|gif|png)$/) === null) {
        this.$toast.open({
          message: 'Không thể chọn tệp ' + file.name + ' do không hợp lệ',
          type: 'warning'
        })
        return
      }
      this.form.surgery_note_image05 = file
      const theReader = new FileReader()
      theReader.onloadend = async () => {
        this.imageSrc5 = await theReader.result
      }
      theReader.readAsDataURL(file)
      this.$refs.inputFilePPT5.value = null
    },
    showPickFile1 () {
      this.$refs.inputFilePPT1.click()
    },
    showPickFile2 () {
      this.$refs.inputFilePPT2.click()
    },
    showPickFile3 () {
      this.$refs.inputFilePPT3.click()
    },
    showPickFile4 () {
      this.$refs.inputFilePPT4.click()
    },
    showPickFile5 () {
      this.$refs.inputFilePPT5.click()
    },
    removeImage (index) {
      this.arrFiles.splice(index, 1)
    },
    async showSurgery () {
      const self = this
      let id = self.$route.query.vId

      if (!id) return

      try {
        self.loading = true
        self.isError = false

        await self.$rf
          .getRequest('DoctorRequest')
          .showSurgery(id)
          .then(r => {
            self.imageSrc1 = r.data.surgery_note_image01 || null
            self.imageSrc2 = r.data.surgery_note_image02 || null
            self.imageSrc3 = r.data.surgery_note_image03 || null
            self.imageSrc4 = r.data.surgery_note_image04 || null
            self.imageSrc5 = r.data.surgery_note_image05 || null

            self.form = {
              surgery_notes: r.data.surgery_notes || '',
              surgical_main_id:
                r.data.surgical_main_id || r.data.surgical_main_name || '',
              surgical_supports_id:
                r.data.surgical_supports_id ||
                r.data.surgical_supports_name ||
                '',
              anaesthetic_main_id:
                r.data.anaesthetic_main_id ||
                r.data.anaesthetic_main_name ||
                '',
              surgery_at: r.data.surgery_at || '',
              completed_at: r.data.completed_at || '',
              before_diagnose:
                r.data.before_diagnose ||
                self.medicalRecordMain?.treat_dept_main_name ||
                '',
              after_diagnose: r.data.after_diagnose || '',
              surgery_method: r.data.surgery_method || '',
              surgery_method_2: r.data.surgery_method_2 || '',
              anaesthetic_method: r.data.anaesthetic_method || '',
              date: r.data.date || '',
              surgery_note_image01: r.data.surgery_note_image01 || '',
              surgery_note_image02: r.data.surgery_note_image02 || '',
              surgery_note_image03: r.data.surgery_note_image03 || '',
              surgery_note_image04: r.data.surgery_note_image04 || '',
              surgery_note_image05: r.data.surgery_note_image05 || '',
              visited_at: r.data.visited_at || '',
              surgery_type: r.data.surgery_type || '',
              number_of_people: r.data.number_of_people || null,
              surgical_extra_id_1:
                r.data.surgical_extra_id_1 ||
                r.data.surgical_extra_name_1 ||
                '',
              surgical_extra_name_1: r.data.surgical_extra_name_1 || '',
              surgical_extra_id_2:
                r.data.surgical_extra_id_2 ||
                r.data.surgical_extra_name_2 ||
                '',
              surgical_extra_name_2: r.data.surgical_extra_name_2 || '',
              anaesthetic_extra_id:
                r.data.anaesthetic_extra_id ||
                r.data.anaesthetic_extra_name ||
                '',
              anaesthetic_extra_name: r.data.anaesthetic_extra_name || '',
              pt_extra_id: r.data.pt_extra_id || r.data.pt_extra_name || '',
              pt_extra_name: r.data.pt_extra_name || '',
              surgery_image_notes: r.data.surgery_image_notes || '',
              template_input:
                r.data.template_input || self.tempSurgeryInput || ''
            }
          })
          .finally(() => {
            this.loading = false
          })
      } catch (error) {
        console.log(error)
      }
    },
    onSubmit () {
      if (
        !this.form.surgery_type ||
        String(this.form.surgery_type).length === 0 ||
        this.form.surgery_type === null ||
        typeof this.form.surgery_type === 'undefined'
      ) {
        this.$message({
          type: 'error',
          message: this.$t('Loại phẫu thuật/ thủ thuật không được để trống')
        })
        this.isError = true
        return
      }

      this.isError = false

      if (isNaN(this.form.anaesthetic_main_id)) {
        this.form.anaesthetic_main_name = this.form.anaesthetic_main_id
        this.form.anaesthetic_main_id = null
      }
      if (isNaN(this.form.surgical_supports_id)) {
        this.form.surgical_supports_name = this.form.surgical_supports_id
        this.form.surgical_supports_id = null
      }
      if (isNaN(this.form.surgical_main_id)) {
        this.form.surgical_main_name = this.form.surgical_main_id
        this.form.surgical_main_id = null
      }

      const findDoctor = doctorId => {
        const data = this.doctors.find(item => item.user_id === doctorId)
        return data
      }

      if (isNaN(this.form.surgical_extra_id_1)) {
        this.form.surgical_extra_name_1 = this.form.surgical_extra_id_1
        this.form.surgical_extra_id_1 = null
      } else {
        const data = findDoctor(this.form.surgical_extra_id_1)
        this.form.surgical_extra_name_1 = data?.name || ''
      }

      if (isNaN(this.form.surgical_extra_id_2)) {
        this.form.surgical_extra_name_2 = this.form.surgical_extra_id_2
        this.form.surgical_extra_id_2 = null
      } else {
        const data = findDoctor(this.form.surgical_extra_id_2)
        this.form.surgical_extra_name_2 = data?.name || ''
      }

      if (isNaN(this.form.anaesthetic_extra_id)) {
        this.form.anaesthetic_extra_name = this.form.anaesthetic_extra_id
        this.form.anaesthetic_extra_id = null
      } else {
        const data = findDoctor(this.form.anaesthetic_extra_id)
        this.form.anaesthetic_extra_name = data?.name || ''
      }

      if (isNaN(this.form.pt_extra_id)) {
        this.form.pt_extra_name = this.form.pt_extra_id
        this.form.pt_extra_id = null
      } else {
        const data = findDoctor(this.form.pt_extra_id)
        this.form.pt_extra_name = data?.name || ''
      }

      this.form.medical_record_id = this.$route.params.id
      if (this.type === 'create') {
        const payloadForm = {
          ...this.form
        }
        this.$emit('onCreate', payloadForm)
        this.closeModal()
        window.$('button#closeModal').trigger('click')
      } else {
        this.updateSurgery(this.form)
        // this.closeModal()
      }
    },
    async updateSurgery (event) {
      this.loading = true
      const id = this.$route.query.vId
      const params = {
        ...event
      }
      const formData = new FormData()
      for (var key in params) {
        formData.append(key, params[key] || '')
      }
      await this.$rf
        .getRequest('DoctorRequest')
        .updateSurgery(id, formData)
        .then(r => {
          this.$emit('onUpdate', this.form)
          this.showSurgery()
          this.closeModal()
          window.$('button#closeModal').trigger('click')
          this.$toast.open({
            message: 'Chỉnh sửa thành công',
            type: 'success'
          })
          this.loading = false
        })
        .finally(() => {
          this.loading = false
        })
    },
    async getDoctors () {
      const clinic_id = this.$clinicId
      const res = await this.$rf
        .getRequest('DoctorRequest')
        .getDoctorsOfWorkspace(clinic_id, { limit: 1000 })
      this.doctors = res.data?.data
    },
    closeModal () {
      this.form = this.defaultForm
      this.form.doc_template_id = null
      this.isError = false
      // this.$refs.inputFilePPT1.value = null
      // this.$refs.inputFilePPT2.value = null
      // this.$refs.inputFilePPT3.value = null
      // this.$refs.inputFilePPT4.value = null
      // this.$refs.inputFilePPT5.value = null
      // this.imageSrc1 = null
      // this.imageSrc2 = null
      // this.imageSrc3 = null
      // this.imageSrc4 = null
      // this.imageSrc5 = null
    },
    openModal (show) {
      window.$('#modalPhieuPhauThuat').modal(show ? 'show' : 'hide')
      let self = this
      self.isError = false
      self.$nextTick(function () {
        self.form = self.defaultForm
        window.$('#modalPhieuPhauThuat').on('shown.bs.modal', function (e) {
          self.form.before_diagnose =
            self.form.before_diagnose ||
            self.medicalRecordMain?.treat_dept_main_name ||
            ''
        })

        window.$('#modalPhieuPhauThuat').on('hidden.bs.modal', function (e) {
          self.form = self.defaultForm
        })
        if (this.type === 'create') {
          const tempSurgeryInput = `<div class="flex"><div class="mr-1 whitespace-nowrap">- Dẫn lưu:</div><div class="content-none mb-1 w-full" style="border-bottom:2px dotted #000"></div></div><div class="flex"><div class="mr-1 whitespace-nowrap">- Bấc:</div><div class="content-none mb-1 w-full" style="border-bottom:2px dotted #000"></div></div><div class="flex"><div class="mr-1 whitespace-nowrap">- Ngày rút:</div><div class="content-none mb-1 w-full" style="border-bottom:2px dotted #000"></div></div><div class="flex"><div class="mr-1 whitespace-nowrap">- Ngày cắt chỉ:</div><div class="content-none mb-1 w-full" style="border-bottom:2px dotted #000"></div></div><div><div class="flex"><div class="mr-1 whitespace-nowrap">- Khác:</div><div class="content-none mb-1 w-full" style="border-bottom:2px dotted #000"></div></div><div class="content-none mt-1 mb-3 pb-2 w-full" style="border-bottom:2px dotted #000"></div></div>`

          this.form = {
            date: moment().format('YYYY-MM-DD'),
            ref_id: '',
            medical_record_id: '',
            surgery_at: moment().format('YYYY-MM-DD HH:mm'),
            completed_at: moment().format('YYYY-MM-DD HH:mm'),
            before_diagnose: '',
            after_diagnose: '',
            surgery_method: '',
            surgery_method_2: '',
            anaesthetic_method: '',
            surgical_main_id: '',
            surgical_supports_id: '',
            anaesthetic_main_id: '',
            surgical_main_name: '',
            surgical_supports_name: '',
            anaesthetic_main_name: '',
            surgery_notes: '',
            surgery_note_image01: '',
            surgery_note_image02: '',
            surgery_note_image03: '',
            surgery_note_image04: '',
            surgery_note_image05: '',
            surgeons_id: '',
            visited_at: '',
            surgery_type: '',
            number_of_people: null,
            surgical_extra_id_1: '',
            surgical_extra_name_1: '',
            surgical_extra_id_2: '',
            surgical_extra_name_2: '',
            anaesthetic_extra_id: '',
            anaesthetic_extra_name: '',
            pt_extra_id: '',
            pt_extra_name: '',
            surgery_image_notes: '',
            template_input: tempSurgeryInput,
            doc_template_id: null
          }
        }
      })
    },
    handleChangeCompletedAt (event) {
      const completedAtTimestamp = moment(event.target.value).unix()
      const surgeryAtTimestamp = moment(this.form.surgery_at).unix()
      if (completedAtTimestamp < surgeryAtTimestamp) {
        window.alert('Thời gian kết thúc phải lớn hơn thời gian bắt đầu')
        this.form.completed_at = ''
      } else {
        this.form.completed_at = event.target.value
      }
    },
    async getDocsTemplateList (search) {
      try {
        this.loading = true
        const params = {
          workspace_id: this.$globalClinic.id,
          limit: 1000,
          sort_by: 'desc',
          type: Number(MENU_EMR_TEMPLATE[5].value)
        }
        if (search) {
          params.keyword = search
        }
        await this.$rf
          .getRequest('DoctorRequest')
          .getDocsTemplateList(params)
          .then(r => {
            const responseData = r.data?.data?.data || []
            this.docsTemplateList = responseData.filter(
              item =>
                item?.author_id === this.$user?.id ||
                item?.emr_temp_name?.is_public === 1
            )
          })
      } catch (error) {
        console.log(error)
      } finally {
        this.loading = false
      }
    },
    handleChangeDocTemplate (selectedDoc) {
      const docTemplateInfo =
        this.docsTemplateList.find(item => item.id === selectedDoc) || {}
      const contentObj = docTemplateInfo.content
        ? JSON.parse(docTemplateInfo.content)
        : {}
      this.form = {
        ...this.form,
        before_diagnose: contentObj.before_diagnose?.value,
        after_diagnose: contentObj.after_diagnose?.value,
        anaesthetic_method: contentObj.anaesthetic_method?.value,
        surgery_image_notes: contentObj.surgery_image_notes?.value,
        surgery_method: contentObj.surgery_method?.value,
        surgery_method_2: contentObj.surgery_method_2?.value,
        surgery_notes: contentObj.surgery_notes?.value,
        template_input: contentObj.template_input?.value
      }
    },
    handleGetTemLabel (data) {
      if (data?.emr_temp_name?.name) {
        return `${data.name} - ${data.emr_temp_name.name}`
      }

      return data.name
    },
    async showModalDrawImage (imageData, index) {
      if (imageData && typeof imageData === 'string') {
        try {
          const newImageSrc = new URLSearchParams(imageData).toString()
          const formatImageSrc = newImageSrc.substring(
            0,
            newImageSrc.length - 1
          )
          const response = await this.$rf
            .getRequest('DoctorRequest')
            .postUrlImage(formatImageSrc)
          const base64File = response.data?.data || ''
          const fileName = imageData.substring(imageData.lastIndexOf('/') + 1)
          const newFile = this.convertBase64tToFile(base64File, fileName)
          this.imageFileSelected = newFile
        } catch (error) {
          console.log(error)
        }
      } else if (!imageData) {
        const newFile = new File([0], `image-${index + Math.random()}.png`, {
          type: 'image/png'
        })
        this.imageFileSelected = newFile
      } else {
        this.imageFileSelected = imageData
      }

      this.selectedImage = index
      window.$('#modalDrawImage').modal('show')
    },
    convertBase64tToFile (dataurl, filename) {
      var arr = dataurl.split(','),
        mime = arr[0].match(/:(.*?);/)[1],
        bstr = atob(arr[arr.length - 1]),
        n = bstr.length,
        u8arr = new Uint8Array(n)
      while (n--) {
        u8arr[n] = bstr.charCodeAt(n)
      }
      return new File([u8arr], filename, { type: mime })
    },
    closeModalDrawImage () {
      this.imageFileSelected = ''
      this.selectedImage = ''
      window.$('#modalDrawImage').modal('hide')
    },
    handleSaveDrawImage (file, base64) {
      switch (this.selectedImage) {
        case 1:
          this.imageSrc1 = base64
          this.form.surgery_note_image01 = file
          break
        case 2:
          this.imageSrc2 = base64
          this.form.surgery_note_image02 = file
          break
        case 3:
          this.imageSrc3 = base64
          this.form.surgery_note_image03 = file
          break
        case 4:
          this.imageSrc4 = base64
          this.form.surgery_note_image04 = file
          break
        case 5:
          this.imageSrc5 = base64
          this.form.surgery_note_image05 = file
          break
      }
      this.closeModalDrawImage()
    }
  }
}
</script>
<style scoped lang="scss">
.ellipsis {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.reverse-ellipsis {
  /* Your move. */
  text-overflow: clip;
  position: relative;
  background-color: #fff;
}

.reverse-ellipsis:before {
  content: '\02026';
  position: absolute;
  z-index: 1;
  left: -2em;
  background-color: inherit;
  padding-left: 1em;
  margin-left: 0.5em;
}

.reverse-ellipsis span {
  min-width: 100%;
  position: relative;
  display: inline-block;
  float: right;
  overflow: visible;
  background-color: inherit;
  text-indent: 0.5em;
}

.reverse-ellipsis span:before {
  content: '';
  position: absolute;
  display: inline-block;
  width: 1em;
  height: 1em;
  background-color: inherit;
  z-index: 200;
  left: -0.5em;
}

.modal {
  overflow: auto !important;
}

::v-deep {
  .ql-editor {
    min-height: 250px;
  }
}
.hodo-overlay {
  display: none;
  position: absolute;
  top: 0;
  left: 0;
  background-color: rgba(196, 196, 196, 0.5);
  // z-index: 10;
  cursor: pointer;
  width: 100%;
  height: 100%;
  align-items: center;
  justify-content: center;
  transition: width 2s linear 1s;
}

.hodo-hover:hover {
  .hodo-overlay {
    display: flex;
  }
}
</style>
