<template>
  <div class="fontForAll text-black my-3 fs-14">
    <div
      class="sticky top-0 flex justify-content-end bg-white text-base font-semibold z-50 mb-3"
    >
      <div v-if="viewForm">
        <a
          v-if="viewForm.pdf_url"
          target="_blank"
          :href="viewForm.pdf_url"
          class="btn bg-pri bd-pri text-white"
          >Xem file gốc</a
        >
      </div>
      <button
        class="btn border-none text-black px-3 mx-2 fs-14 fw-500"
        @click="openModalSignPDF()"
      >
        <div class="loading">
          <div v-if="loading" class="spinner-border mr-2" role="status">
            <span class="visually-hidden">Loading...</span>
          </div>
        </div>
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="26"
          height="26"
          viewBox="0 0 16 16"
        >
          <path
            fill="#03267A"
            d="M9.021 1.772a2.445 2.445 0 0 1 3.458 3.457l-.272.271l.086.086a2 2 0 0 1 0 2.828l-1.44 1.44a.5.5 0 0 1-.707-.707l1.44-1.44a1 1 0 0 0 0-1.414l-.086-.086l-5.646 5.647a.5.5 0 0 1-.233.131l-4 1a.5.5 0 0 1-.595-.643l1.25-3.75a.5.5 0 0 1 .12-.195l6.625-6.625Zm2.75.707a1.445 1.445 0 0 0-2.042 0L3.187 9.02l-.925 2.774l2.982-.745l6.527-6.527a1.445 1.445 0 0 0 0-2.043ZM4.967 15c-1.235 0-2.208-.566-2.855-1.107l1.284-.32a3.2 3.2 0 0 0 1.57.427c.406 0 .824-.164 1.27-.457c.447-.293.878-.687 1.314-1.095l.113-.107c.385-.361.784-.737 1.166-.996c.408-.278.95-.53 1.517-.311c.376.144.62.41.791.736c.161.306.272.688.38 1.105c.05.19.133.374.23.498a.46.46 0 0 0 .114.112c.025.015.036.015.039.015c.122 0 .331-.082.63-.286c.185-.126.344-.252.502-.379c.098-.078.197-.157.302-.235c.279-.209.52-.349.697-.438a3.05 3.05 0 0 1 .278-.124l.02-.008l.007-.002l.003-.001h.001l.16.473l-.159-.474a.5.5 0 0 1 .321.947l-.004.002a2.141 2.141 0 0 0-.175.08c-.13.064-.321.174-.55.346c-.059.043-.135.104-.223.174c-.181.143-.409.324-.618.466c-.31.212-.741.46-1.192.46c-.436 0-.751-.265-.946-.517a2.435 2.435 0 0 1-.405-.858c-.11-.423-.196-.7-.296-.89c-.09-.168-.17-.232-.266-.27c-.082-.03-.245-.033-.595.206c-.313.212-.655.533-1.058.912l-.1.093c-.431.405-.92.855-1.449 1.202c-.53.348-1.14.621-1.818.621Z"
          />
        </svg>
        {{ $t("multidisciplinary_board.btn_sign") }}
      </button>
      <button
        class="btn border-none text-black px-3 mx-2 fs-14 fw-500"
        @click="showModalSaveDocTemplate(true)"
      >
        <div class="loading">
          <div v-if="loading" class="spinner-border mr-2" role="status">
            <span class="visually-hidden">Loading...</span>
          </div>
        </div>
        <svg
          width="24"
          height="24"
          viewBox="0 0 24 24"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
          class="copy-icon"
        >
          <path
            d="M16 20H8C7.20435 20 6.44129 19.6839 5.87868 19.1213C5.31607 18.5587 5 17.7956 5 17V7C5 6.73478 4.89464 6.48043 4.70711 6.29289C4.51957 6.10536 4.26522 6 4 6C3.73478 6 3.48043 6.10536 3.29289 6.29289C3.10536 6.48043 3 6.73478 3 7V17C3 18.3261 3.52678 19.5979 4.46447 20.5355C5.40215 21.4732 6.67392 22 8 22H16C16.2652 22 16.5196 21.8946 16.7071 21.7071C16.8946 21.5196 17 21.2652 17 21C17 20.7348 16.8946 20.4804 16.7071 20.2929C16.5196 20.1054 16.2652 20 16 20ZM10 13C10 13.2652 10.1054 13.5196 10.2929 13.7071C10.4804 13.8946 10.7348 14 11 14H16C16.2652 14 16.5196 13.8946 16.7071 13.7071C16.8946 13.5196 17 13.2652 17 13C17 12.7348 16.8946 12.4804 16.7071 12.2929C16.5196 12.1054 16.2652 12 16 12H11C10.7348 12 10.4804 12.1054 10.2929 12.2929C10.1054 12.4804 10 12.7348 10 13ZM21 8.94C20.9896 8.84813 20.9695 8.75763 20.94 8.67V8.58C20.8919 8.47718 20.8278 8.38267 20.75 8.3L14.75 2.3C14.6673 2.22222 14.5728 2.15808 14.47 2.11C14.4402 2.10576 14.4099 2.10576 14.38 2.11C14.2784 2.05174 14.1662 2.01434 14.05 2H10C9.20435 2 8.44129 2.31607 7.87868 2.87868C7.31607 3.44129 7 4.20435 7 5V15C7 15.7956 7.31607 16.5587 7.87868 17.1213C8.44129 17.6839 9.20435 18 10 18H18C18.7956 18 19.5587 17.6839 20.1213 17.1213C20.6839 16.5587 21 15.7956 21 15V9C21 9 21 9 21 8.94ZM15 5.41L17.59 8H16C15.7348 8 15.4804 7.89464 15.2929 7.70711C15.1054 7.51957 15 7.26522 15 7V5.41ZM19 15C19 15.2652 18.8946 15.5196 18.7071 15.7071C18.5196 15.8946 18.2652 16 18 16H10C9.73478 16 9.48043 15.8946 9.29289 15.7071C9.10536 15.5196 9 15.2652 9 15V5C9 4.73478 9.10536 4.48043 9.29289 4.29289C9.48043 4.10536 9.73478 4 10 4H13V7C13.0027 7.34114 13.0636 7.67933 13.18 8H11C10.7348 8 10.4804 8.10536 10.2929 8.29289C10.1054 8.48043 10 8.73478 10 9C10 9.26522 10.1054 9.51957 10.2929 9.70711C10.4804 9.89464 10.7348 10 11 10H19V15Z"
            fill="#03267A"
          />
        </svg>
        {{ $t("multidisciplinary_board.btn_save_tem") }}
      </button>
      <button
        class="btn border-none text-black px-3 mx-2 fs-14 fw-500"
        @click="exportPdfFile()"
        :disabled="loading"
      >
        <div class="loading" v-if="loading">
          <div class="spinner-border mr-2" role="status">
            <span class="visually-hidden">Loading...</span>
          </div>
        </div>
        <svg
          width="20"
          height="20"
          viewBox="0 0 20 20"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M16 14H17C17.2652 14 17.5196 13.8946 17.7071 13.7071C17.8946 13.5196 18 13.2652 18 13V7C18 6.73478 17.8946 6.48043 17.7071 6.29289C17.5196 6.10536 17.2652 6 17 6H3C2.73478 6 2.48043 6.10536 2.29289 6.29289C2.10536 6.48043 2 6.73478 2 7V13C2 13.2652 2.10536 13.5196 2.29289 13.7071C2.48043 13.8946 2.73478 14 3 14H4V9H16V14ZM4 4V0H16V4H17C17.7956 4 18.5587 4.31607 19.1213 4.87868C19.6839 5.44129 20 6.20435 20 7V13C20 13.7956 19.6839 14.5587 19.1213 15.1213C18.5587 15.6839 17.7956 16 17 16H16V20H4V16H3C2.20435 16 1.44129 15.6839 0.87868 15.1213C0.31607 14.5587 0 13.7956 0 13L0 7C0 6.20435 0.31607 5.44129 0.87868 4.87868C1.44129 4.31607 2.20435 4 3 4H4ZM6 18H14V11H6V18ZM6 4H14V2H6V4Z"
            fill="#03267A"
          />
        </svg>
        {{ $t("multidisciplinary_board.lbl_print") }}
      </button>
      <button
        class="btn border-none text-black px-3 mx-2 fs-14 fw-500"
        @click="ModalUpdate(true)"
        :disabled="loading"
      >
        <svg
          width="18"
          height="18"
          viewBox="0 0 18 18"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M15.75 8.99995C15.5511 8.99995 15.3603 9.07897 15.2197 9.21962C15.079 9.36028 15 9.55104 15 9.74995V14.25C15 14.4489 14.921 14.6396 14.7803 14.7803C14.6397 14.9209 14.4489 15 14.25 15H3.75C3.55109 15 3.36032 14.9209 3.21967 14.7803C3.07902 14.6396 3 14.4489 3 14.25V3.74995C3 3.55104 3.07902 3.36028 3.21967 3.21962C3.36032 3.07897 3.55109 2.99995 3.75 2.99995H8.25C8.44891 2.99995 8.63968 2.92094 8.78033 2.78028C8.92098 2.63963 9 2.44887 9 2.24995C9 2.05104 8.92098 1.86028 8.78033 1.71962C8.63968 1.57897 8.44891 1.49995 8.25 1.49995H3.75C3.15326 1.49995 2.58097 1.73701 2.15901 2.15896C1.73705 2.58092 1.5 3.15322 1.5 3.74995V14.25C1.5 14.8467 1.73705 15.419 2.15901 15.8409C2.58097 16.2629 3.15326 16.5 3.75 16.5H14.25C14.8467 16.5 15.419 16.2629 15.841 15.8409C16.2629 15.419 16.5 14.8467 16.5 14.25V9.74995C16.5 9.55104 16.421 9.36028 16.2803 9.21962C16.1397 9.07897 15.9489 8.99995 15.75 8.99995ZM4.5 9.56995V12.75C4.5 12.9489 4.57902 13.1396 4.71967 13.2803C4.86032 13.4209 5.05109 13.5 5.25 13.5H8.43C8.5287 13.5005 8.62655 13.4816 8.71793 13.4443C8.80931 13.407 8.89242 13.352 8.9625 13.2825L14.1525 8.08495L16.2825 5.99995C16.3528 5.93023 16.4086 5.84728 16.4467 5.75589C16.4847 5.66449 16.5043 5.56646 16.5043 5.46745C16.5043 5.36845 16.4847 5.27042 16.4467 5.17902C16.4086 5.08763 16.3528 5.00468 16.2825 4.93495L13.1025 1.71745C13.0328 1.64716 12.9498 1.59136 12.8584 1.55329C12.767 1.51521 12.669 1.49561 12.57 1.49561C12.471 1.49561 12.373 1.51521 12.2816 1.55329C12.1902 1.59136 12.1072 1.64716 12.0375 1.71745L9.9225 3.83995L4.7175 9.03745C4.64799 9.10753 4.593 9.19065 4.55567 9.28202C4.51835 9.3734 4.49943 9.47125 4.5 9.56995V9.56995ZM12.57 3.30745L14.6925 5.42995L13.6275 6.49495L11.505 4.37245L12.57 3.30745ZM6 9.87745L10.4475 5.42995L12.57 7.55245L8.1225 12H6V9.87745Z"
            fill="#03267A"
          />
        </svg>
        {{ $t("multidisciplinary_board.lbl_modify_short") }}
      </button>
      <div
        class="text-black mx-2 fs-14 fw-500 h-4 cursor-pointer mb-3"
        @click="backList"
        v-if="!loading"
      >
        <svg
          width="40"
          height="46"
          viewBox="0 0 48 49"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M26.6276 24.7096L33.6976 17.6376C33.8887 17.4531 34.041 17.2324 34.1458 16.9884C34.2507 16.7444 34.3058 16.4819 34.3081 16.2164C34.3104 15.9508 34.2598 15.6874 34.1593 15.4416C34.0587 15.1959 33.9102 14.9725 33.7224 14.7848C33.5346 14.597 33.3113 14.4485 33.0655 14.3479C32.8198 14.2473 32.5564 14.1967 32.2908 14.1991C32.0253 14.2014 31.7628 14.2565 31.5188 14.3613C31.2748 14.4662 31.0541 14.6185 30.8696 14.8096L23.7976 21.8796L16.7276 14.8096C16.5431 14.6185 16.3225 14.4662 16.0784 14.3613C15.8344 14.2565 15.572 14.2014 15.3064 14.1991C15.0409 14.1967 14.7775 14.2473 14.5317 14.3479C14.2859 14.4485 14.0626 14.597 13.8748 14.7848C13.6871 14.9725 13.5386 15.1959 13.438 15.4416C13.3374 15.6874 13.2868 15.9508 13.2891 16.2164C13.2914 16.4819 13.3466 16.7444 13.4514 16.9884C13.5563 17.2324 13.7086 17.4531 13.8996 17.6376L20.9696 24.7076L13.8996 31.7796C13.7086 31.964 13.5563 32.1847 13.4514 32.4287C13.3466 32.6728 13.2914 32.9352 13.2891 33.2007C13.2868 33.4663 13.3374 33.7297 13.438 33.9755C13.5386 34.2213 13.6871 34.4446 13.8748 34.6323C14.0626 34.8201 14.2859 34.9686 14.5317 35.0692C14.7775 35.1698 15.0409 35.2204 15.3064 35.2181C15.572 35.2157 15.8344 35.1606 16.0784 35.0558C16.3225 34.9509 16.5431 34.7986 16.7276 34.6076L23.7976 27.5376L30.8696 34.6076C31.0541 34.7986 31.2748 34.9509 31.5188 35.0558C31.7628 35.1606 32.0253 35.2157 32.2908 35.2181C32.5564 35.2204 32.8198 35.1698 33.0655 35.0692C33.3113 34.9686 33.5346 34.8201 33.7224 34.6323C33.9102 34.4446 34.0587 34.2213 34.1593 33.9755C34.2598 33.7297 34.3104 33.4663 34.3081 33.2007C34.3058 32.9352 34.2507 32.6728 34.1458 32.4287C34.041 32.1847 33.8887 31.964 33.6976 31.7796L26.6276 24.7076V24.7096Z"
            fill="black"
          />
        </svg>
      </div>
    </div>
    <!-- // -->
    <div ref="viewDetail" v-if="viewForm" class="w-full">
      <div id="phieuPhauThuatViewDetail" class="mx-auto bg-white hodo-print">
        <div class="block">
          <div class="col-3 flex">
            <div class="flex flex-col justify-center">
              <div class="uppercase">
                <div>
                  <p class="fs-17 mb-0">
                    {{
                      $globalClinic && $globalClinic.organization
                        ? $globalClinic.organization.authority_in_charge
                        : ""
                    }}
                  </p>
                </div>
                <div class>
                  <p class="fs-17 mb-0">
                    {{
                      $globalClinic && $globalClinic.organization
                        ? $globalClinic.organization.name
                        : ""
                    }}
                  </p>
                </div>
                <!-- <div class>
                <p class="fs-14 mb-0">{{$globalClinic ? $globalClinic.name : ''}}</p>
                </div>-->
              </div>
            </div>
          </div>
          <div class="col-6 font-bold flex flex-col justify-center uppercase">
            <p class="fs-24">PHIẾU PHẪU THUẬT/THỦ THUẬT</p>
          </div>
          <div class="col-3 text-right">
            <div class="inline-block">
              <div class="flex">
                <label class="font fs-17 whitespace-nowrap">MS:</label>
                <p class="fs-17 mb-0 ml-1">14/BV-01</p>
              </div>
              <div class="flex">
                <label class="font fs-17 whitespace-nowrap">Số vào viện:</label>
                <p class="fs-17 mb-0 ml-1">
                  {{ emrData.ref_id || "........................." }}
                </p>
              </div>
            </div>
          </div>
        </div>

        <div class="block mt-3 mb-2">
          <div class="col-7 inline-block align-top">
            <div class="flex">
              <p class="fs-18">Họ tên người bệnh:</p>
              <p class="fs-18 mb-0 ml-1 font-bold">{{ person.name }}</p>
            </div>
          </div>

          <div class="col-4 mb-1 inline-block align-top">
            <div class="flex justify-between">
              <div class="flex">
                <p class="fs-18">Tuổi:</p>
                <p class="ml-1 font-bold mb-0 fs-18">{{ person.age }}</p>
              </div>
              <div class="flex">
                <p class="fs-18">Giới tính:</p>
                <p class="fs-18 ml-1 font-bold mb-0">{{ getGender() }}</p>
              </div>
            </div>
          </div>
          <div class="flex mb-1">
            <p class="fs-18">Địa chỉ:</p>
            <p class="fs-18 mb-0 ml-1">{{ getAddress(person) }}</p>
          </div>
          <div class="block mb-1">
            <div class="col-7 inline-block align-top">
              <div class="flex">
                <p class="fs-18">Phòng:</p>
                <p class="fs-18 mb-0 ml-1" v-show="person.admission_room">
                  {{ person.admission_room }}
                </p>
              </div>
            </div>
            <div class="col-4 inline-block align-top">
              <div class="flex">
                <p class="fs-18">Giường:</p>
                <p class="fs-18 mb-0 ml-1" v-show="person.admission_bed">
                  {{ person.admission_bed }}
                </p>
              </div>
            </div>
          </div>
        </div>

        <div class="mb-2">
          <div class="mb-1">
            <div :class="!formatterVisitedAt ? 'flex' : 'overflow-hidden'">
              <div
                class="whitespace-nowrap mr-1 fs-18"
                :class="!formatterVisitedAt ? '' : 'float-left'"
              >
                Vào viện lúc:
              </div>
              <div v-if="!formatterVisitedAt" class="content-none mb-1 w-full"></div>
              <div
                v-else
                class="fontForAll font-bold fs-18"
                v-html="formatterVisitedAt"
              ></div>
            </div>
          </div>
          <!-- <div class="mb-1 flex">
          <p class=" mr-1">Vào viện lúc:</p>
          <p class=" mb-0 mr-1">{{ formatterVisitedAt }}</p>
          <p
            class=" mb-0"
          >{{ viewForm.surgery_at ? ' ngày ' + formatDate(viewForm.surgery_at) : '' }}</p>
          </div>-->

          <div class="block">
            <div class="col-6">
              <div class="mb-1">
                <div :class="!viewForm.surgery_at ? 'flex' : 'overflow-hidden'">
                  <div
                    class="whitespace-nowrap mr-1 fs-18"
                    :class="!viewForm.surgery_at ? '' : 'float-left'"
                  >
                    Phẫu thuật/ thủ thuật lúc:
                  </div>
                  <div v-if="!viewForm.surgery_at" class="content-none mb-1 w-full"></div>
                  <div
                    v-else
                    class="fontForAll font-bold fs-18"
                    v-html="
                      `${
                        viewForm.surgery_at
                          ? formatTime(viewForm.surgery_at) + ' giờ,'
                          : ''
                      }${
                        viewForm.surgery_at
                          ? ' ngày ' + formatDate(viewForm.surgery_at)
                          : ''
                      }`
                    "
                  ></div>
                </div>
              </div>
            </div>
            <div class="col-6">
              <div class="mb-1">
                <div :class="!viewForm.completed_at ? 'flex' : 'overflow-hidden'">
                  <div
                    class="whitespace-nowrap mr-1 fs-18"
                    :class="!viewForm.completed_at ? '' : 'float-left'"
                  >
                    Kết thúc lúc:
                  </div>
                  <div
                    v-if="!viewForm.completed_at"
                    class="content-none mb-1 w-full"
                  ></div>
                  <div
                    v-else
                    class="fontForAll font-bold fs-18"
                    v-html="
                      `${
                        viewForm.completed_at
                          ? formatTime(viewForm.completed_at) + ' giờ,'
                          : ''
                      }${
                        viewForm.completed_at
                          ? ' ngày ' + formatDate(viewForm.completed_at)
                          : ''
                      }`
                    "
                  ></div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="mb-1">
          <div class="mb-1 fs-18">- Chẩn đoán:</div>
          <div class="mb-1">
            <div :class="!viewForm.before_diagnose ? 'flex' : 'overflow-hidden'">
              <div
                class="whitespace-nowrap mr-1 fs-18"
                :class="!viewForm.before_diagnose ? '' : 'float-left'"
              >
                &nbsp;+ Trước phẫu thuật/thủ thuật:
              </div>
              <div
                v-if="!viewForm.before_diagnose"
                class="content-none mb-1 w-full"
              ></div>
              <div
                v-else
                class="fontForAll fs-18"
                v-html="viewForm.before_diagnose"
              ></div>
            </div>
          </div>
          <div class="mb-1">
            <div :class="!viewForm.after_diagnose ? 'flex' : 'overflow-hidden'">
              <div
                class="whitespace-nowrap mr-1 fs-18"
                :class="!viewForm.after_diagnose ? '' : 'float-left'"
              >
                &nbsp;+ Sau phẫu thuật/thủ thuật:
              </div>
              <div v-if="!viewForm.after_diagnose" class="content-none mb-1 w-full"></div>
              <div v-else class="fontForAll fs-18" v-html="viewForm.after_diagnose"></div>
            </div>
          </div>
        </div>
        <div class="mb-1">
          <div :class="!viewForm.surgery_method ? 'flex' : 'overflow-hidden'">
            <div
              class="whitespace-nowrap mr-1 fs-18"
              :class="!viewForm.surgery_method ? '' : 'float-left'"
            >
              - Phương pháp phẫu thuật/ thủ thuật:
            </div>
            <div v-if="!viewForm.surgery_method" class="content-none mb-1 w-full"></div>
            <div v-else class="fontForAll fs-18" v-html="viewForm.surgery_method"></div>
          </div>
          <div class="flex">
            <div class="content-none mt-3 mb-2 w-full"></div>
            <div class="flex whitespace-nowrap items-center ml-1">
              <div class="mr-1 fs-18">Loại phẫu thuật/ thủ thuật (bắt buộc):</div>
              <div class="border-box px-1 fs-18">
                {{ viewForm.surgery_type || "&nbsp;" }}
              </div>
            </div>
          </div>
        </div>
        <div class="mb-1">
          <div :class="!viewForm.surgery_method_2 ? 'flex' : 'overflow-hidden'">
            <div
              class="whitespace-nowrap mr-1 fs-18"
              :class="!viewForm.surgery_method_2 ? '' : 'float-left'"
            >
              - Phương pháp phẫu thuật/ thủ thuật thứ 2:
            </div>
            <div v-if="!viewForm.surgery_method_2" class="content-none mb-1 w-full"></div>
            <div v-else class="fontForAll fs-18" v-html="viewForm.surgery_method_2"></div>
          </div>
        </div>
        <div class="mb-1">
          <div :class="!viewForm.anaesthetic_method ? 'flex' : 'overflow-hidden'">
            <div
              class="whitespace-nowrap mr-1 fs-18"
              :class="!viewForm.anaesthetic_method ? '' : 'float-left'"
            >
              - Phương pháp vô cảm:
            </div>
            <div
              v-if="!viewForm.anaesthetic_method"
              class="content-none mb-1 w-full"
            ></div>
            <div
              v-else
              class="fontForAll fs-18"
              v-html="viewForm.anaesthetic_method"
            ></div>
          </div>
        </div>
        <div class="mb-1 flex items-end">
          <div class="mr-1 whitespace-nowrap fs-18">
            - Tổng số người tham gia phẫu thuật/ thủ thuật:
          </div>
          <template v-if="!viewForm.number_of_people">
            <div class="w-16 mb-1">
              <div class="content-none"></div>
            </div>
            <div class="fs-18">người tham gia</div>
          </template>
          <div v-else class="fs-18">{{ viewForm.number_of_people }} người tham gia</div>
        </div>

        <div class="mb-1">
          <div
            :class="
              !viewForm.surgical_main && !viewForm.surgical_main_name
                ? 'flex'
                : 'overflow-hidden'
            "
          >
            <div
              class="whitespace-nowrap mr-1 fs-18"
              :class="
                !viewForm.surgical_main && !viewForm.surgical_main_name
                  ? ''
                  : 'float-left'
              "
            >
              - Bác sĩ phẫu thuật/thủ thuật chính:
            </div>
            <div
              v-if="!viewForm.surgical_main && !viewForm.surgical_main_name"
              class="content-none mb-1 w-full"
            ></div>
            <div
              v-else
              class="fontForAll font-bold fs-18"
              v-html="
                (viewForm.surgical_main && viewForm.surgical_main.name) ||
                viewForm.surgical_main_name
              "
            ></div>
          </div>
        </div>

        <div class="block">
          <div class="col-6">
            <div class="mb-1">
              <div :class="!viewForm.surgical_extra_name_1 ? 'flex' : 'overflow-hidden'">
                <div
                  class="whitespace-nowrap mr-1 fs-18"
                  :class="!viewForm.surgical_extra_name_1 ? '' : 'float-left'"
                >
                  - BS phụ 1:
                </div>
                <div
                  v-if="!viewForm.surgical_extra_name_1"
                  class="content-none mb-1 w-full"
                ></div>
                <div
                  v-else
                  class="fontForAll font-bold fs-18"
                  v-html="viewForm.surgical_extra_name_1"
                ></div>
              </div>
            </div>
          </div>
          <div class="col-6">
            <div class="mb-1">
              <div :class="!viewForm.surgical_extra_name_2 ? 'flex' : 'overflow-hidden'">
                <div
                  class="whitespace-nowrap mr-1 fs-18"
                  :class="!viewForm.surgical_extra_name_2 ? '' : 'float-left'"
                >
                  BS phụ 2:
                </div>
                <div
                  v-if="!viewForm.surgical_extra_name_2"
                  class="content-none mb-1 w-full"
                ></div>
                <div
                  v-else
                  class="fontForAll font-bold fs-18"
                  v-html="viewForm.surgical_extra_name_2"
                ></div>
              </div>
            </div>
          </div>
        </div>

        <div class="mb-1">
          <div
            :class="
              !viewForm.anaesthetic_main && !viewForm.anaesthetic_main_name
                ? 'flex'
                : 'overflow-hidden'
            "
          >
            <div
              class="whitespace-nowrap mr-1 fs-18"
              :class="
                !viewForm.anaesthetic_main && !viewForm.anaesthetic_main_name
                  ? ''
                  : 'float-left'
              "
            >
              - Bác sĩ gây mê hồi sức chính:
            </div>
            <div
              v-if="!viewForm.anaesthetic_main && !viewForm.anaesthetic_main_name"
              class="content-none mb-1 w-full"
            ></div>
            <div
              v-else
              class="fontForAll font-bold fs-18"
              v-html="
                (viewForm.anaesthetic_main && viewForm.anaesthetic_main.name) ||
                viewForm.anaesthetic_main_name
              "
            ></div>
          </div>
        </div>

        <div class="block">
          <div class="col">
            <div class="mb-1">
              <div :class="!viewForm.anaesthetic_extra_name ? 'flex' : 'overflow-hidden'">
                <div
                  class="whitespace-nowrap mr-1 fs-18"
                  :class="!viewForm.anaesthetic_extra_name ? '' : 'float-left'"
                >
                  - Gây mê phụ:
                </div>
                <div
                  v-if="!viewForm.anaesthetic_extra_name"
                  class="content-none mb-1 w-full"
                ></div>
                <div
                  v-else
                  class="fontForAll font-bold fs-18"
                  v-html="viewForm.anaesthetic_extra_name"
                ></div>
              </div>
            </div>
          </div>
          <!-- <div class="col-6">
            <div class="mb-1">
              <div :class="!viewForm.pt_extra_name ? 'flex' : 'overflow-hidden'">
                <div
                  class="whitespace-nowrap mr-1 fs-18"
                  :class="!viewForm.pt_extra_name ? '' : 'float-left'"
                >Giúp việc PT:</div>
                <div v-if="!viewForm.pt_extra_name" class="content-none mb-1 w-full"></div>
                <div v-else class="fontForAll font-bold fs-18" v-html=" viewForm.pt_extra_name"></div>
              </div>
            </div>
          </div>-->
        </div>

        <div class="mt-2 hodo-table border-black">
          <table class="w-full" id="table_phieu_phau_thuat">
            <thead class="w-full">
              <tr>
                <td class="" style="width: 50%">
                  <div
                    class="font w-full font-bold uppercase text-center py-1 content-size fs-16"
                  >
                    Lược Đồ Phẫu Thuật/ Thủ Thuật
                  </div>
                </td>
                <td class="" style="width: 50%">
                  <div
                    class="font w-full font-bold uppercase text-center py-1 content-size fs-16"
                  >
                    Trình tự Phẫu Thuật/ Thủ Thuật
                  </div>
                </td>
                <!-- <td>
                  <div class="font font-bold uppercase text-center">
                    <span>Trình tự phẫu thuật thủ thuật</span>
                  </div>
                </td>-->
              </tr>
            </thead>
            <tbody class="w-full">
              <tr>
                <td style="width: 50%" class="align-top">
                  <div class="text-center min-h-250">
                    <div
                      class="py-1"
                      v-for="(img, index) in images"
                      :key="`image_${index}`"
                    >
                      <img
                        @click="onShowMedia(images, index)"
                        :src="img"
                        class="h-40 cursor-pointer"
                        :alt="`Surgery Note Image 0${index + 1}`"
                      />
                    </div>
                    <div
                      class="py-1 hidden-on-pdf"
                      v-for="(file, index) in pdfFiles"
                      :key="`pdf_${index}`"
                    >
                      <div
                        class="inline-block w-24 h-24 cursor-pointer"
                        @click="onShowMedia(pdfFiles, index)"
                      >
                        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 50 50">
                          <path
                            d="M 7 2 L 7 48 L 43 48 L 43 14.59375 L 42.71875 14.28125 L 30.71875 2.28125 L 30.40625 2 Z M 9 4 L 29 4 L 29 16 L 41 16 L 41 46 L 9 46 Z M 31 5.4375 L 39.5625 14 L 31 14 Z"
                          />
                          <text
                            fill="currentColor"
                            x="50%"
                            y="60%"
                            text-anchor="middle"
                            dominant-baseline="middle"
                            class="fs-12 font-bold cursor-pointer"
                          >
                            {{ appUtils.getExtensionOfFile(file).toUpperCase() }}
                          </text>
                        </svg>
                      </div>
                    </div>
                    <div
                      class="py-1 hidden-on-pdf"
                      v-for="(file, index) in otherFiles"
                      :key="`other_file_${index}`"
                    >
                      <div class="inline-block w-24 h-24 cursor-default">
                        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 50 50">
                          <path
                            d="M 7 2 L 7 48 L 43 48 L 43 14.59375 L 42.71875 14.28125 L 30.71875 2.28125 L 30.40625 2 Z M 9 4 L 29 4 L 29 16 L 41 16 L 41 46 L 9 46 Z M 31 5.4375 L 39.5625 14 L 31 14 Z"
                          />
                          <text
                            fill="currentColor"
                            x="50%"
                            y="60%"
                            text-anchor="middle"
                            dominant-baseline="middle"
                            class="fs-12 font-bold cursor-default"
                          >
                            {{ appUtils.getExtensionOfFile(file).toUpperCase() }}
                          </text>
                        </svg>
                      </div>
                    </div>
                  </div>
                </td>
                <td class="align-top content-size" style="width: 50%">
                  <div class="fs-18">- Mô tả cho hình ảnh lược đồ:</div>
                  <template v-if="!viewForm.surgery_image_notes">
                    <div class="content-none my-3 pb-2 w-full"></div>
                    <div class="content-none my-3 pb-2 w-full"></div>
                    <div class="content-none my-3 pb-2 w-full"></div>
                    <!-- <div class="content-none my-3 pb-2 w-full"></div> -->
                    <!-- <div class="content-none my-3 pb-2 w-full"></div> -->
                    <!-- <div class="content-none my-3 pb-2 w-full"></div> -->
                    <!-- <div class="content-none my-3 pb-2 w-full"></div> -->
                    <!-- <div class="content-none my-3 pb-2 w-full"></div>
                    <div class="content-none my-3 pb-2 w-full"></div>
                    <div class="content-none my-3 pb-2 w-full"></div>
                    <div class="content-none my-3 pb-2 w-full"></div>
                    <div class="content-none my-3 pb-2 w-full"></div>-->
                  </template>
                  <div
                    v-else
                    class="content-size fs-18"
                    v-html="viewForm.surgery_image_notes"
                  ></div>

                  <div class="fs-18">- Trình tự phẫu thuật thủ thuật:</div>
                  <template v-if="!viewForm.surgery_notes">
                    <div class="content-none mt-2 mb-3 pb-1 w-full"></div>
                    <div class="content-none mb-3 pb-1 w-full"></div>
                    <div class="content-none mb-3 pb-1 w-full"></div>
                    <div class="content-none mb-3 pb-1 w-full"></div>
                    <!-- <div class="content-none mb-3 pb-1 w-full"></div> -->
                    <!-- <div class="content-none mb-3 pb-1 w-full"></div> -->
                    <!-- <div class="content-none mb-3 pb-1 w-full"></div> -->
                    <!-- <div class="content-none mb-3 pb-1 w-full"></div> -->
                    <!-- <div class="content-none mb-3 pb-1 w-full"></div>
                    <div class="content-none mb-3 pb-1 w-full"></div>
                    <div class="content-none mb-3 pb-1 w-full"></div>
                    <div class="content-none mb-3 pb-1 w-full"></div>-->
                  </template>
                  <div
                    v-else
                    class="content-size fs-18"
                    v-html="viewForm.surgery_notes"
                  ></div>
                  <div
                    v-if="!viewForm.template_input"
                    class="content-size fs-18"
                    v-html="tempSurgeryInput"
                  ></div>
                  <div
                    v-else
                    class="mb-2 content-size fs-18"
                    v-html="viewForm.template_input"
                  ></div>
                </td>
              </tr>
            </tbody>
          </table>
        </div>

        <div class="mt-4 hodo-table border-black">
          <div class="block">
            <div class="col-6"></div>
            <div class="col-6 text-right">
              <div class="inline-block">
                <div class="text-center font fs-17">
                  {{ viewForm.date && getHMDMYVN(viewForm.date) }}
                </div>
                <div class="font-bold text-center font mb-16 fs-17">
                  PHẪU THUẬT/THỦ THUẬT VIÊN
                </div>
                <div class="font-bold fs-18 text-center font">
                  {{
                    (viewForm.surgical_main && viewForm.surgical_main.name) ||
                    viewForm.surgical_main_name
                  }}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <!-- modal -->
    <modalUpdateForm :type="'update'" @onUpdate="showSurgery()" />
    <ModalSaveDocumentTemplate
      ref="ModalSaveDocumentTemplate"
      :doc-content="docContent"
      :doc-type="MENU_EMR_TEMPLATE[5].value"
    />

    <CoolLightBox
      :items="getImagesForCoolight(list_medias)"
      :index="index_media"
      :useZoomBar="true"
      @close="index_media = null"
    ></CoolLightBox>
    <ModalSignPDF
      containerIDProps="SurgeryContainerPDF"
      elementIDProps="SurgerySignerPDF"
      imageIDProps="SurgeryImageID"
      ref="SurgerySignerPDF"
      :envelopeName="envelopeName"
      @onSuccess="handleSignDocumentSuccess"
    />
  </div>
</template>

<script>
import modalUpdateForm from './modalPhieuPhauThuat'
import appUtils from '../../../utils/appUtils'
import { mapState } from 'vuex'
import BoxNumber from '../../BoxNumber.vue'
import { mixinPrint } from '../../../utils/mixinPrint'
import ModalSaveDocumentTemplate from '../ModalSaveDocumentTemplate.vue'
import {
  ENVELOPE_DOCUMENT_TYPE,
  ENVELOPE_NAME_TYPE,
  MENU_EMR_TEMPLATE
} from '../../../utils/constants'
import { mixinGetPersonInfo } from '@/utils/mixinGetPersonInfo'
import ModalSignPDF from '../../Signature/ModalSignPDF.vue'
import SignatureRequest from '../../../api/request/SignatureRequest'
import { mixinSignPdf } from '../../../utils/mixinSignPdf'

export default {
  name: 'PhieuPhauThuatDetail',
  components: {
    modalUpdateForm,
    BoxNumber,
    ModalSaveDocumentTemplate,
    ModalSignPDF
  },
  mixins: [mixinPrint, mixinGetPersonInfo, mixinSignPdf],
  data () {
    return {
      viewForm: null,
      loading: false,
      type: null,
      index_media: null,
      list_medias: null,
      images: [],
      pdfFiles: [],
      otherFiles: [],
      appUtils,
      MENU_EMR_TEMPLATE,
      tempSurgeryInput: `<div class="flex"><div class="mr-1 whitespace-nowrap">- Dẫn lưu:</div><div class="content-none mb-1 w-full" style="border-bottom:2px dotted #000"></div></div><div class="flex"><div class="mr-1 whitespace-nowrap">- Bấc:</div><div class="content-none mb-1 w-full" style="border-bottom:2px dotted #000"></div></div><div class="flex"><div class="mr-1 whitespace-nowrap">- Ngày rút:</div><div class="content-none mb-1 w-full" style="border-bottom:2px dotted #000"></div></div><div class="flex"><div class="mr-1 whitespace-nowrap">- Ngày cắt chỉ:</div><div class="content-none mb-1 w-full" style="border-bottom:2px dotted #000"></div></div><div><div class="flex"><div class="mr-1 whitespace-nowrap">- Khác:</div><div class="content-none mb-1 w-full" style="border-bottom:2px dotted #000"></div></div><div class="content-none mt-1 mb-3 pb-2 w-full" style="border-bottom:2px dotted #000"></div></div>`
    }
  },
  mounted () {
    this.showSurgery()
    this.type = this.$route.query.type
  },
  watch: {
    '$route.query.type' (val) {
      this.type = val
    }
  },
  computed: {
    ...mapState({
      person: (state) => state.person,
      medicalRecordMain: (state) => state.medicalRecordMain,
      emrData: (state) => state.emrData
    }),
    envelopeName () {
      return this.handleGetEnvelopeName(ENVELOPE_NAME_TYPE.emr, this.emrData)
    },
    formatterVisitedAt () {
      const visitedAt =
        this.viewForm.visited_at || this.medicalRecordMain?.reception_at || null
      return visitedAt !== null
        ? `${this.formatTime(visitedAt)} giờ, ngày ${this.formatDate(visitedAt)}`
        : ''
    },
    docContent () {
      return {
        before_diagnose: {
          label: 'Chẩn đoán trước phẫu thuật/ thủ thuật',
          value: this.viewForm?.before_diagnose,
          editor: 1
        },
        after_diagnose: {
          label: 'Chẩn đoán sau phẫu thuật/ thủ thuật',
          value: this.viewForm?.after_diagnose,
          editor: 1
        },
        surgery_method: {
          label: 'Phương pháp phẫu thuật/ thủ thuật',
          value: this.viewForm?.surgery_method,
          editor: 1
        },
        surgery_method_2: {
          label: 'Phương pháp phẫu thuật/ thủ thuật thứ 2',
          value: this.viewForm?.surgery_method_2,
          editor: 1
        },
        anaesthetic_method: {
          label: 'Phương pháp vô cảm',
          value: this.viewForm?.anaesthetic_method,
          editor: 1
        },
        surgery_image_notes: {
          label: 'Mô tả hình ảnh cho lược đồ',
          value: this.viewForm?.surgery_image_notes,
          editor: 1
        },
        template_input: {
          label: 'Nội dung',
          value: this.viewForm?.template_input,
          editor: 1
        },
        surgery_notes: {
          label: 'Trình tự phẫu thuật thủ thuật',
          value: this.viewForm?.surgery_notes,
          editor: 1
        }
      }
    }
  },
  methods: {
    getImagesForCoolight (files) {
      let results = []
      if (files) {
        files.forEach((file) => {
          results.push({
            src: file
          })
        })
      }
      return results
    },
    onShowMedia (medias, index) {
      let self = this
      self.list_medias = medias
      self.index_media = index
    },
    ModalUpdate (show) {
      window.$('#modalPhieuPhauThuat').modal(show ? 'show' : 'hide')
    },
    async showSurgery () {
      const self = this
      let id = self.$route.query.vId
      self.loading = true
      await self.$rf
        .getRequest('DoctorRequest')
        .showSurgery(id)
        .then(async (r) => {
          self.images = []
          self.pdfFiles = []
          self.otherFiles = []
          self.viewForm = r.data

          if (self.viewForm?.surgery_note_image01) {
            if (appUtils.isImage(self.viewForm?.surgery_note_image01)) {
              self.images.push(self.viewForm?.surgery_note_image01)
            } else if (appUtils.isPdf(self.viewForm?.surgery_note_image01)) {
              self.pdfFiles.push(self.viewForm?.surgery_note_image01)
            } else {
              self.otherFiles.push(self.viewForm?.surgery_note_image01)
            }
          }
          if (self.viewForm?.surgery_note_image02) {
            if (appUtils.isImage(self.viewForm?.surgery_note_image02)) {
              self.images.push(self.viewForm?.surgery_note_image02)
            } else if (appUtils.isPdf(self.viewForm?.surgery_note_image02)) {
              self.pdfFiles.push(self.viewForm?.surgery_note_image02)
            } else {
              self.otherFiles.push(self.viewForm?.surgery_note_image02)
            }
          }
          if (self.viewForm?.surgery_note_image03) {
            if (appUtils.isImage(self.viewForm?.surgery_note_image03)) {
              self.images.push(self.viewForm?.surgery_note_image03)
            } else if (appUtils.isPdf(self.viewForm?.surgery_note_image03)) {
              self.pdfFiles.push(self.viewForm?.surgery_note_image03)
            } else {
              self.otherFiles.push(self.viewForm?.surgery_note_image03)
            }
          }
          // if (self.viewForm?.surgery_note_image04) {
          //   if (appUtils.isImage(self.viewForm?.surgery_note_image04)) {
          //     self.images.push(self.viewForm?.surgery_note_image04)
          //   } else if (appUtils.isPdf(self.viewForm?.surgery_note_image04)) {
          //     self.pdfFiles.push(self.viewForm?.surgery_note_image04)
          //   } else {
          //     self.otherFiles.push(self.viewForm?.surgery_note_image04)
          //   }
          // }
          // if (self.viewForm?.surgery_note_image05) {
          //   if (appUtils.isImage(self.viewForm?.surgery_note_image05)) {
          //     self.images.push(self.viewForm?.surgery_note_image05)
          //   } else if (appUtils.isPdf(self.viewForm?.surgery_note_image05)) {
          //     self.pdfFiles.push(self.viewForm?.surgery_note_image05)
          //   } else {
          //     self.otherFiles.push(self.viewForm?.surgery_note_image05)
          //   }
          // }
        })
        .finally(() => {
          self.loading = false
        })
    },
    async updateSurgery (event) {
      const id = this.$route.query.vId
      const params = {
        ...event
      }
      const formData = new FormData()
      for (var key in params) {
        formData.append(key, params[key] || '')
      }
      await this.$rf
        .getRequest('DoctorRequest')
        .updateSurgery(id, formData)
        .then((r) => {
          this.showSurgery()
          this.$toast.open({
            message: 'Chỉnh sửa thành công',
            type: 'success'
          })
        })
        .finally(() => {
          this.loading = false
        })
    },
    submit () {
      let id = this.$route.query.vId
      if (!id) {
        this.createSurgery()
      } else this.updateSurgery(id)
      this.showSurgery()
    },
    goToView (type) {
      this.$router.replace({
        query: { tab: this.$route.query.tab, vId: this.$route.query.vId, type }
      })
    },
    backList () {
      this.viewForm = null
      this.$router.replace({ query: { tab: this.$route.query.tab } })
    },
    async exportPdfFile (isOpen = true) {
      this.loading = true
      const response = await this.exportPdf(
        {
          refDocument: this.$refs.viewDetail,
          fileName: 'PhieuPhauThuat_',
          path: 'surgery',
          type: 'surgery',
          type_id: 'surgery_id',
          uuid: '',
          appendStyle: true
        },
        isOpen
      ).finally(() => {
        this.loading = false
      })
      console.log({ response })
      return response
    },
    // format
    getGender () {
      return appUtils.getGender(this.person.gender)
    },
    formatTime (date) {
      return window.moment(date).format('HH:mm')
    },
    formatDate (date) {
      return window.moment(date).format('DD/MM/YYYY')
    },
    getDate (date) {
      return window.moment(date).format('YYYY-MM-DD')
    },
    getDateTime (date) {
      let string = this.moment(date).locale(this.getLangCode)
      string = string.format('HH:mm-DD/MM/YYYY')
      return string.replace('-', ' ngày ')
    },
    getHMDMYVN (date) {
      const dt = this.moment(date)
      // let hm = dt.clone().format('HH:mm')
      const d = dt.clone().format('DD')
      const m = dt.clone().format('MM')
      const y = dt.clone().format('YYYY')
      return 'Ngày ' + d + ' tháng ' + m + ' năm ' + y
    },
    showModalSaveDocTemplate (show) {
      this.$refs.ModalSaveDocumentTemplate.showModalSaveDocTemplate(show)

      // window.$('#modalSaveDocTemplate-6').modal(show ? 'show' : 'hide')
    },
    async openModalSignPDF () {
      if (this.viewForm?.surgical_main_id !== this.$user?.id) {
        this.$toast.open({
          message: 'Bạn không có quyền ký tài liệu này',
          type: 'error'
        })
        return
      }

      const documentLink = await this.exportPdfFile(false)

      if (!documentLink) {
        this.$toast.open({
          message: 'Không tìm thấy tài liệu cần ký',
          type: 'error'
        })
        return
      }

      const procedureResponse = await this.getSignProcedure()
      const procedureSelected = procedureResponse?.data?.[0] || {}

      const signatories = procedureSelected?.sign_nodes?.reduce((arr, node) => {
        const signs = node?.node_signatories?.map((item) => {
          let user
          if (item?.user_role === 'operated_doctor') {
            user = {
              id: this.viewForm.surgical_main_id,
              name: this.viewForm?.surgical_main?.name,
              email: this.viewForm?.surgical_main?.email
            }
          }

          return {
            ...item,
            user,
            user_id: user?.id
          }
        })

        return arr.concat(signs)
      }, [])

      const signerInfo = signatories?.find((item) => item?.user_id === this.$user?.id)
      if (!signerInfo) {
        this.$toast.open({
          type: 'error',
          message: 'Bạn không có quyền ký tài liệu này'
        })

        return
      }

      this.$refs.SurgerySignerPDF.handleOpen(
        documentLink,
        ENVELOPE_DOCUMENT_TYPE.SURGERY_FORM,
        Number(this.$route.query.vId),
        signerInfo?.user_id,
        signatories,
        null,
        procedureSelected?.id
      )
    },
    async getSignProcedure () {
      try {
        const params = {
          page_num: 1,
          page_size: 1000,
          order: 'desc',
          sort_by: 'id',
          document_type: ENVELOPE_DOCUMENT_TYPE.SURGERY_FORM,
          ws_id: this.$globalClinic?.id
        }

        const request = new SignatureRequest()

        const response = await request.getSignProcedures(params)
        return response.data
      } catch (error) {
        console.log(error)
      }
    },
    async handleSignDocumentSuccess (dataBase64, responseEnvelope) {
      try {
        this.loading = true
        const file = appUtils.convertBase64tToFile(
          dataBase64,
          parseInt(Date.now() / 1000)
        )
        const url = await this.uploadFiles(file, Number(this.$route.query.vId))
        await this.handleUpdateEnvelopeDocumentURL(responseEnvelope, url)
        window.open(url, '_blank')
        this.$emit('onSignSuccess')
        // await this.$rf
        //   .getRequest('DoctorRequest')
        //   .updateConsultationV2(this.consultationData?.id, {
        //     document_link: url
        //   })

        // await this.signDocument()
      } catch (error) {
        console.log(error)
      } finally {
        this.loading = false
      }
    },
    async uploadFiles (file, id) {
      const params = {
        file,
        FolderTarget: `consultation`,
        IdTarget: id
      }
      const formData = new FormData()

      for (const key in params) {
        formData.append(key, params[key])
      }

      const res = await this.$rf.getRequest('DoctorRequest').uploadFileTenant(formData)
      return res.data.fileUrl
    },
    async handleUpdateEnvelopeDocumentURL (responseEnvelope, url) {
      if (!responseEnvelope?.id) return

      try {
        const request = new SignatureRequest()

        const response = await request.updateEnvelope(responseEnvelope.id, {
          document_url: url
        })

        if (response.status === 200) {
          this.$toast.open({
            message: 'Ký thành công',
            type: 'success'
          })
        }
      } catch (error) {
        console.log(error)
      }
    }
  }
}
</script>
<style lang="scss" scoped>
::v-deep {
  html,
  body {
    font-family: "Times New Roman", Times, Tinos, serif !important;
    font-size: 1rem;
    line-height: 1.25rem;
    font-weight: 500;
    font-style: normal;
    letter-spacing: 0em;
    color: #000000 !important;
  }

  img {
    max-width: 100%;
  }

  img,
  svg {
    vertical-align: middle;
  }

  .content-none {
    width: 100%;
    border-bottom: 2px dotted;
  }

  .whitespace-nowrap {
    white-space: nowrap;
  }

  .font {
    font-family: "Times New Roman", Tinos, Times, serif !important;
  }

  .checkbox {
    display: inline-flex;
    cursor: pointer;
    position: relative;
  }

  p {
    margin: 0;
  }

  .text-italic {
    font-style: italic;
  }

  .relative {
    position: relative;
  }

  .absolute {
    position: absolute;
  }

  .top-0 {
    top: 0;
  }

  .right-0 {
    right: 0;
  }

  .left-0 {
    left: 0;
  }

  .px-8 {
    padding-right: 2rem;
    padding-left: 2rem;
  }

  .overflow-hidden {
    overflow: hidden;
  }

  .float-left {
    float: left;
  }

  .float-right {
    float: right;
  }

  .float-none {
    float: none;
  }

  .block {
    display: block;
  }

  .inline-block {
    display: inline-block !important;
  }

  .flex {
    display: flex;
  }

  .flex-col {
    flex-direction: column;
  }

  .align-top {
    vertical-align: top;
  }

  .text-center {
    text-align: center;
  }

  .text-left {
    text-align: left;
  }

  .text-right {
    text-align: right;
  }

  .normal-case {
    text-transform: none;
  }

  .uppercase {
    text-transform: uppercase;
  }

  .capitalize {
    text-transform: capitalize;
  }

  .leading-none {
    line-height: 1;
  }

  .align-middle {
    vertical-align: middle;
  }

  .justify-center {
    justify-content: center;
  }

  .justify-end {
    justify-content: end;
  }

  .justify-between {
    justify-content: space-between;
  }

  .items-start {
    align-items: flex-start;
  }

  .items-center {
    align-items: center;
  }

  .col-4 {
    width: 33.33% !important;
    display: inline-block !important;
    vertical-align: top;
  }

  .col-3 {
    width: 25% !important;
    display: inline-block !important;
    vertical-align: top;
  }

  .col-4 {
    width: 33.3333333% !important;
    display: inline-block !important;
    vertical-align: top;
  }

  .col-5 {
    width: 41% !important;
    display: inline-block !important;
    vertical-align: top;
  }

  .col-6 {
    width: 50% !important;
    display: inline-block !important;
    vertical-align: top;
  }

  .col-7 {
    width: 58.3333333% !important;
    display: inline-block !important;
    vertical-align: top;
  }

  .col-12 {
    width: 100% !important;
    display: inline-block !important;
    vertical-align: top;
  }

  .w-full {
    width: 100% !important;
  }

  .w-half {
    width: 50% !important;
  }

  .w-one-third {
    width: 33.333% !important;
  }

  .w-two-thirds {
    width: 66.667% !important;
  }

  .font-normal {
    font-weight: 400;
  }

  .font-bold {
    font-weight: 700;
  }

  .text-xs {
    font-size: 0.75rem;
    line-height: 1rem;
  }

  .text-sm {
    font-size: 1rem;
    line-height: 1.25rem;
  }

  .text-xl {
    font-size: 1.25rem;
    line-height: 1.75rem;
  }

  .text-base {
    font-size: 1rem;
    line-height: 1.5rem;
  }

  .px-1 {
    padding-left: 0.25rem;
    padding-right: 0.25rem;
  }

  .py-1 {
    padding-top: 0.25rem;
    padding-bottom: 0.25rem;
  }

  .pt-12 {
    padding-top: 3rem;
  }

  .p-0 {
    padding: 0;
  }

  .p-1 {
    padding: 0.25rem;
  }

  .pt-3 {
    padding-top: 0.75rem;
  }

  .pl-3 {
    padding-left: 0.75rem;
  }

  .pl-12 {
    padding-left: 3rem;
  }

  .pl-16 {
    padding-left: 4rem;
  }

  .pr-12 {
    padding-right: 3rem;
  }

  .pr-16 {
    padding-right: 4rem;
  }

  .mt-2 {
    margin-top: 0.5rem;
  }

  .mt-3 {
    margin-top: 0.75rem;
  }

  .mt-4 {
    margin-top: 1rem;
  }

  .m-6 {
    margin: 1.5rem;
  }

  .ml-6 {
    margin-left: 1.5rem;
  }

  .mt-auto {
    margin-top: auto;
  }

  .mt-6 {
    margin-top: 1.5rem;
  }

  .mt-8 {
    margin-top: 2rem;
  }

  .mt-10 {
    margin-top: 2.5rem;
  }

  .mt-12 {
    margin-top: 3rem;
  }

  .mt-16 {
    margin-top: 4rem;
  }

  .mb-16 {
    margin-bottom: 4rem;
  }

  .mt-24 {
    margin-top: 6rem;
  }

  .mt-44 {
    margin-top: 11rem;
  }

  .ml-auto {
    margin-left: auto;
  }

  .ml-1 {
    margin-left: 0.25rem;
  }

  .ml-2 {
    margin-left: 0.5rem;
  }

  .ml-3 {
    margin-left: 0.75rem;
  }

  .ml-4 {
    margin-left: 1rem;
  }

  .ml-5 {
    margin-left: 1.25rem;
  }

  .mr-1 {
    margin-right: 0.25rem;
  }

  .mb-0 {
    margin-bottom: 0;
  }

  .mb-1 {
    margin-bottom: 0.25rem;
  }

  .mb-2 {
    margin-bottom: 0.5rem;
  }

  .mb-3 {
    margin-bottom: 0.75rem;
  }

  .mb-6 {
    margin-bottom: 1.5rem;
  }

  .mb-8 {
    margin-bottom: 2rem;
  }

  .hodo-table figure {
    margin: 0 0 1rem !important;
  }

  .hodo-table table {
    border-collapse: collapse !important;
    width: 100% !important;
    // border: 1px solid #dbdde0 !important;
    margin-top: 1rem !important;
  }

  .hodo-table.border-black table {
    border-color: black !important;
  }

  .hodo-table table thead th {
    text-transform: uppercase !important;
  }

  .hodo-table table td,
  .hodo-table table th {
    padding: 8px !important;
    border: 1px solid #dbdde0 !important;
  }

  .hodo-table.border-black table td,
  .hodo-table.border-black table th {
    border-color: black !important;
  }

  .box-number {
    border: 1px solid;
    height: 1.5rem !important;
  }

  .box-item {
    font-family: "Times New Roman", Tinos, Times, serif !important;
    height: 1.5rem !important;
    width: 1.5rem !important;
    border-right: 1px solid;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .box-item-bhyt {
    font-family: "Times New Roman", Tinos, Times, serif !important;
    height: 0.5rem !important;
    padding: 0.5rem;
    border-right: 1px solid;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .box-item-bhyt:last-child,
  .box-item:last-child {
    border-right: none;
  }

  .checkbox {
    display: inline-flex;
    cursor: pointer;
    position: relative;
  }

  .table-width-full table {
    width: 100% !important;
  }

  .table-width-full {
    padding-right: 3rem;
  }

  .el-table--border,
  .el-table--group {
    border-top: 1px solid #000;
    border-right: 1px solid #000;
    border-left: 1px solid #000;
  }

  .el-table th.el-table__cell.is-leaf {
    border-right: 1px solid #000;
    border-bottom: 1px solid #000;
  }

  .el-table__row:last-child,
  .el-table__cell:last-child,
  .el-table th.el-table__cell.is-leaf:last-child {
    border-right: none;
  }

  .el-table__cell {
    border-bottom: 1px solid #000;
    border-right: 1px solid #000;
  }

  .el-table--border th.el-table__cell > div.cell {
    line-height: 1.25 !important;
  }

  thead th.padding-header {
    padding: 0 8px !important;
  }

  thead th.header-text-left {
    text-align: left !important;
    padding: 0 8px !important;
  }

  .break-words {
    overflow-wrap: break-word !important;
    word-break: break-word !important;
    word-wrap: break-word !important;
    white-space: break-spaces !important;
  }

  .border-box {
    border: 1px solid #000;
  }

  .html2pdf__page-break {
    page-break-before: always;
  }

  .h-40 {
    height: 10rem;
  }

  .border {
    border: 1px solid #e0e0e0 !important;
  }

  .border-t {
    border-top: 1px solid #000;
  }

  .border-b {
    border-bottom: 1px solid #000;
  }

  .border-l {
    border-left: 1px solid #000;
  }

  .border-r {
    border-right: 1px solid #000;
  }

  .copy-icon {
    width: 24px !important;
    height: 24px !important;
  }
}
</style>
